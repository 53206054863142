import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import PublicHeader from "../../components/header/publicHeader"
import Footer from "./Footer"

function Rules() {
  let navigate = useNavigate()
  return (
    <MainContainer>
      <PublicHeader />
      <ContentContainer className="d-flex flex-column align-items-center">
        <HeaderHolder>
          <Title>School Rules</Title>
          <Text>
            {`At Ace Trucking Academy, we're committed to creating a safe and productive learning environment. To ensure your success, please follow these important school rules:`}
          </Text>
        </HeaderHolder>
        <InternalHeader>1. Student Parking:</InternalHeader>
        <InternalText>
          Students must park in designated areas only.
        </InternalText>
        <InternalHeader>2. Driver's License, CLP, and DOT:</InternalHeader>
        <InternalText>
          To operate our equipment, you must possess a valid Driver’s License,
          Commercial Learner's Permit (CLP), and Department of Transportation
          (DOT) documents.
        </InternalText>
        <InternalHeader>3. Medication:</InternalHeader>
        <InternalText>
          If you're taking any medication (prescribed or over-the-counter), you
          must inform the Director of Training before operating our equipment.
          Certain medications can impair your ability to drive.
        </InternalText>
        <InternalHeader>4. Attendance:</InternalHeader>
        <InternalText>
          Unexcused absences will not be permitted and may result in
          disciplinary action. To excuse an absence, ensure you provide a
          doctor's note or proof of appointment that conflicts with your
          scheduled training time. Students forfeit days of training that they
          miss during their scheduled class time.
        </InternalText>
        <InternalHeader>5. Start Time:</InternalHeader>
        <InternalText>
          Our school day begins promptly at 6:30 am, 11:30 am, or 3:30 pm,
          unless otherwise specified. Answering roll calls on time is crucial.
          Lunch and training breaks are included in our attendance policy.
        </InternalText>
        <InternalHeader>6. Drugs and Alcohol:</InternalHeader>
        <InternalText>
          Attending class under the influence of drugs or alcohol is strictly
          prohibited and will result in immediate removal from class. Possession
          of illegal substances on school grounds, including vehicles, will lead
          to expulsion.
        </InternalText>
        <InternalHeader>7. Dress Code:</InternalHeader>
        <InternalText>
          Maintain a professional appearance by avoiding halter tops, muscle
          shirts, tank tops, sandals, or thongs. Shirts must be worn at all
          times. Good personal hygiene is expected and required. Violations may
          result in dismissal.
        </InternalText>
        <InternalHeader>8. CDL Equipment:</InternalHeader>
        <InternalText>
          Absolutely no smoking, eating, or drinking in our vehicles.
          Disregarding this rule may lead to removal. Misuse of CDL equipment
          will not be tolerated.
        </InternalText>
        <InternalHeader>9. Trash:</InternalHeader>
        <InternalText>
          Clean up after yourself. Use the provided trash cans and wastebaskets
          to keep our school grounds clean and orderly.
        </InternalText>
        <InternalHeader>10. Always Think and Practice Safety:</InternalHeader>
        <InternalText>
          Do not operate equipment without an instructor present. Follow
          instructor directions, indicated by whistles. Moving without
          permission may result in consequences. Trucks in the yard must remain
          in 1st gear or reverse. On-campus, maintain a speed limit of 10mph in
          your personal vehicles.
        </InternalText>
        <InternalHeader>11. Weapons:</InternalHeader>
        <InternalText>
          Firearms or weapons of any kind are strictly prohibited on school
          grounds or in our vehicles. Violating this policy results in expulsion
          from the program.
        </InternalText>
        <InternalHeader>12. Gambling:</InternalHeader>
        <InternalText>
          Gambling of any kind is not allowed on school property.
        </InternalText>
        <InternalHeader>13. Fighting:</InternalHeader>
        <InternalText>
          Maintain good behavior while in training. Fighting is unacceptable and
          will lead to expulsion.
        </InternalText>
        <InternalHeader>14. Listen and Learn:</InternalHeader>
        <InternalText>
          Respect your instructors and fellow students. Insubordination,
          profanity, racial slurs, or refusal to follow directions won't be
          tolerated. Address concerns through the school supervisor or the
          Education Manager/Admissions.
        </InternalText>
      </ContentContainer>

      <Footer />
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`
const ContentContainer = styled(Container)`
  margin-top: 98px;
`
const HeaderHolder = styled.div`
  padding: 98px 0;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--text-title-color);
`
const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--text-title-color);
  margin-top: 24px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-color);
  margin-top: 10px;

  //
  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--text-title-color);
`

export default Rules
