import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { setJWTToken, setUserId } from "../../api/auth"
import { basic_api } from "../../api/axios_helper"
import Notification from "../../components/Notification"

import Container from "react-bootstrap/Container"
import CustomButton from "../../components/button/CustomButton"
import Form from "react-bootstrap/Form"

import logoIcon from "../../assets/logo.png"

function Signup() {
  let navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    function deleteAllCookies() {
      var cookies = document.cookie.split(";")

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i]
        var eqPos = cookie.indexOf("=")
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
      }
    }

    deleteAllCookies()
  }, [])

  const _register = () => {
    if (isLoading) return
    const formData = new FormData()
    formData.append("email", email)
    formData.append("first_name", firstName)
    formData.append("last_name", lastName)
    formData.append("password", password)
    setIsloading(true)
    basic_api
      .post("/api/v1/users/", formData)
      .then(({ data }) => {
        Notification.Success("OTP sent to your mailbox")
        _verifyEmail()
      })
      .catch(error => {
        console.log(error?.response?.data)
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _verifyEmail = () => {
    const formData = new FormData()
    formData.append("email", email)
    basic_api
      .post("/api/v1/users/request_otp/", formData)
      .then(({ data }) => {
        setIsloading(false)
        Notification.Success("Signin completed!")
        navigate("/signup/mail-check", { state: { email } })
      })
      .catch(error => {
        console.log(error?.response?.data)
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  return (
    <ContentContainer>
      <CenterContentContainer>
        <div className="d-flex justify-content-center">
          <HeaderImg src={logoIcon}></HeaderImg>
        </div>
        <Title className="d-flex justify-content-center">
          Create an account
        </Title>
        <Form
          onSubmit={e => {
            e.preventDefault()
            _register()
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <FormLabel>First Name*</FormLabel>
            <FormControl
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              type="text"
              placeholder="Enter your first name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <FormLabel>Last Name*</FormLabel>
            <FormControl
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              type="text"
              placeholder="Enter your last name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <FormLabel>Email*</FormLabel>
            <FormControl
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              placeholder="Enter your email"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <FormLabel>Password*</FormLabel>
            <FormControl
              value={password}
              onChange={e => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              required
            />
            <Form.Text className="text-muted">
              Must be at least 8 characters
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <FormCheck
              type="checkbox"
              label="I have read Terms and Conditions and Privacy Policy"
              required
            />
          </Form.Group>

          <ButtonContainer>
            <CustomButton type="submit" title={"Get started"} />
          </ButtonContainer>
        </Form>
        <FooterTextContainer className="d-flex justify-content-center">
          <ForgotTextAlt>Already have an account?</ForgotTextAlt>
          <ForgotText onClick={() => navigate("/signin")}>Sign in</ForgotText>
        </FooterTextContainer>
      </CenterContentContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled(Container)`
  width: 100%;
  height: 100vh;
  display: flex;
  margin-top: 100px;
  justify-content: center;
`
const CenterContentContainer = styled.div`
  width: 360px;
`
const Title = styled.h1`
  margin-top: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: val(--text-title-color);
`
const Text = styled.p`
  margin-top: 12px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: val(--text-color);
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0;
`
const FormLabel = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`
const FormControl = styled(Form.Control)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const ForgotText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  margin-bottom: 24px;
  cursor: pointer;
`

const ForgotTextAlt = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-right: 4px;
  margin-bottom: 24px;
`

const FooterTextContainer = styled.div`
  margin-top: 32px;
`
const FormCheck = styled(Form.Check)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`

const HeaderImg = styled.img`
  height: 60px;
  width: 60px;
`

export default Signup
