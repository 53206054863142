export const calculateGradeAndGPAWithPoints = (points, status) => {
  if (status === 'not_started') {
    return { grade: '-', gpa: '-' };
  }
  if (points >= 94 && points <= 100) {
    return { grade: 'A', gpa: 4.0 };
  } else if (points >= 87 && points <= 93) {
    return { grade: 'BA', gpa: 3.5 };
  } else if (points >= 80 && points <= 86) {
    return { grade: 'B', gpa: 3.0 };
  } else if (points >= 75 && points <= 79) {
    return { grade: 'CB', gpa: 2.5 };
  } else if (points >= 70 && points <= 74) {
    return { grade: 'C', gpa: 2.0 };
  } else if (points >= 65 && points <= 69) {
    return { grade: 'DC', gpa: 1.5 };
  } else if (points >= 60 && points <= 64) {
    return { grade: 'D', gpa: 1.0 };
  }
  return { grade: 'F', gpa: 0.0 };
};
