import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import PublicHeader from "../../components/header/publicHeader"
import Footer from "./Footer"

function About() {
  let navigate = useNavigate()
  return (
    <MainContainer>
      <PublicHeader />
      <ContentContainer className="d-flex flex-column align-items-center">
        <HeaderHolder>
          <Title>About Us</Title>
          <Text>{`Our Story - Driven by Excellence`}</Text>
        </HeaderHolder>
        <InternalText>
          Ace Trucking Academy was founded by Hansel Andujar, a former CDL
          student who transformed into a leading figure in CDL training. With a
          team of experienced professionals, including Hansel as Executive
          Director and Fernando as Managing Director, we're committed to
          equipping individuals with the skills and knowledge needed to excel in
          the trucking industry.
        </InternalText>
        <InternalHeader>Our Vision:</InternalHeader>
        <InternalText>Empowering Your Journey, One Mile at a Time</InternalText>
        <InternalText>
          At Ace Trucking Academy, our vision is to empower individuals from all
          walks of life through comprehensive, hands-on CDL training. We believe
          that each mile traveled brings the potential for personal growth and
          success. Join us on the road to a brighter future!
        </InternalText>
        <InternalHeader>Our Mission:</InternalHeader>
        <InternalText>Your Success, Our Mission</InternalText>
        <InternalText>
          We're on a mission to provide exceptional CDL training. We're all
          about transparency, comprehensive training, and personalized support.
          We genuinely care about your success, which goes beyond simply
          obtaining your CDL; it extends to securing job opportunities and
          thriving in the entire trucking industry. In addition to connecting
          you with leading recruiters in the field, we give you insights into
          everything from dispatching to starting your own trucking company.
          Plus, we've got you covered on securing trucking insurance and more.
          Your success is our all-encompassing mission!
        </InternalText>
        <InternalHeader>Our Core Values:</InternalHeader>
        <InternalText>Excellence in Training</InternalText>
        <InternalText>Student-Centered Approach</InternalText>
        <InternalText>Transparency and Honesty</InternalText>
        <InternalText>Second Chances and Community Support</InternalText>
        <InternalText>Unwavering Commitment</InternalText>
        <InternalHeader>Community Support:</InternalHeader>
        <InternalText>Changing Lives, One Opportunity at a Time</InternalText>
        <InternalText>
          For years, we've partnered with multiple community organizations to
          help Houston residents get their foot in the door to a career in
          trucking. We are mission-oriented, helping those with criminal
          backgrounds enter the truck driving industry, offering second chances
          to those in need and changing lives for those who need it most.
        </InternalText>
        <InternalHeader>Certification:</InternalHeader>
        <InternalText>
          We are certified by the Texas Workforce Commission Trade Schools and
          Colleges Network, ensuring the quality and rigor of our programs.
        </InternalText>
        <InternalText>
          Graduate Success: 2000 Graduates and Counting. With over 2000
          graduates and counting, we're proud to be Houston's #1 CDL school,
          providing top-rated training and changing lives for the better.
        </InternalText>
      </ContentContainer>
      <Footer/>
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`
const ContentContainer = styled(Container)`
  margin-top: 98px;
`
const HeaderHolder = styled.div`
  padding: 98px 0;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--text-title-color);
`
const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--text-title-color);
  margin-top: 24px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-color);
  margin-top: 10px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--text-title-color);
`

const FooterContainer = styled.footer`
  width: 100%;
  padding-bottom: 36px;
`
const FooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
`

export default About
