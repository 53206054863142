import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import deleteIcon from "../../assets/delete.svg";
import CustomButton from "../button/CustomButton";

function DeleteModal({
  isShow,
  title,
  description,
  buttonText = "Yes, Sure",
  customIcon = deleteIcon,
  callback = () => null,
  handleClosecallback = () => null,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(isShow);
  }, [isShow]);

  const handleClose = () => {
    setShow(false);
    handleClosecallback();
  };
  const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <ModalHeader closeButton>
        <Image src={customIcon} />
      </ModalHeader>
      <Modal.Body>
        <BodyTitle>{title}</BodyTitle>
        <BodyContent>{description}</BodyContent>
      </Modal.Body>
      <ModalFooter>
        <CustomButton title={buttonText} onClick={callback} />
      </ModalFooter>
    </Modal>
  );
}

const ModalHeader = styled(Modal.Header)`
  border: 0;
  margin: 24px;
  margin-bottom: 0;
`;

const Image = styled.img`
  width: 48px;
  height: 48px;
  position: absolute;
  margin-left: auto;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const BodyTitle = styled.p`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #101828;
`;

const BodyContent = styled.p`
  margin: 0;
  margin-top: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #475467;
`;

const ModalFooter = styled(Modal.Footer)`
  border: 0;
  margin: 24px;
  margin-top: 0;
`;

export default DeleteModal;
