import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import PublicHeader from "../../components/header/publicHeader"
import Footer from "./Footer"

function Terms() {
  let navigate = useNavigate()
  return (
    <MainContainer>
      <PublicHeader />
      <ContentContainer className="d-flex flex-column align-items-center">
        <HeaderHolder>
          <Title>Terms & Conditions</Title>
          <Text>
            {`Your privacy is important to us at Ace Trucking Academy. We respect your privacy regarding any information we may collect from you across our website.`}
          </Text>
        </HeaderHolder>
        <InternalText>
          Our terms and conditions provide important information about payments,
          refunds, and your responsibilities as a student. You must read and
          agree to our terms and conditions to enroll.
        </InternalText>
        <InternalHeader>1. Acceptance of Terms</InternalHeader>
        <InternalText>
          1.1. By accessing or using Ace Trucking Academy's web app (the "App"),
          you acknowledge that you have read, understood, and agree to comply
          with these Terms and Conditions. If you do not agree with any part of
          these terms, please refrain from using the App.
        </InternalText>
        <InternalText>
          1.2. Ace Trucking Academy reserves the right to modify, revise, or
          update these Terms and Conditions at its sole discretion. Any changes
          will be effective immediately upon posting on the App. Your continued
          use of the App following such changes constitutes your acceptance of
          the revised terms.
        </InternalText>
        <InternalHeader>2. User Registration</InternalHeader>
        <InternalText>
          2.1. To access specific features and services provided by the App, you
          may be required to register an account. You agree to provide accurate
          and complete information during the registration process and to update
          your information promptly to maintain its accuracy.
        </InternalText>
        <InternalHeader>3. Use of the App</InternalHeader>
        <InternalText>
          3.1. You agree to use the App in accordance with all applicable local,
          state, national, and international laws and regulations. You shall not
          engage in any unlawful or unauthorized activities while using the App.
        </InternalText>
        <InternalHeader>4. Privacy and Data Protection</InternalHeader>
        <InternalText>
          4.1. Your use of the App is also governed by our Privacy Policy, which
          outlines how we collect, use, and protect your personal information.
          Please review our Privacy Policy to understand our practices.
        </InternalText>
        <InternalHeader>5. Intellectual Property</InternalHeader>
        <InternalText>
          5.1. All content and materials available on the App, including but not
          limited to text, graphics, logos, images, and software, are the
          exclusive property of Ace Trucking Academy and are protected by
          copyright and other intellectual property laws. You agree not to
          reproduce, modify, distribute, or create derivative works based on the
          content from the App.
        </InternalText>
        <InternalHeader>6. Student Code of Conduct</InternalHeader>
        <InternalText>
          6.1. When using the App, you are expected to adhere to our Student
          Code of Conduct, which outlines expected behavior and standards for
          respectful and responsible interaction within our educational
          community. Please review our Student Code of Conduct for complete
          details.
        </InternalText>
        <InternalHeader>7. School Rules</InternalHeader>
        <InternalText>
          7.1. Users of the App who are currently enrolled students or are
          considering enrollment must also abide by our school rules and
          policies, as applicable. Please review our School Rules for
          comprehensive guidelines.
        </InternalText>
        <InternalHeader>8. Third-Party Links and Content</InternalHeader>
        <InternalText>
          8.1. The App may contain links to third-party websites or services.
          Ace Trucking Academy does not endorse, control, or take responsibility
          for the content, privacy practices, or actions of any third-party
          websites or services. Your interaction with such third parties is at
          your own risk.
        </InternalText>
        <InternalHeader>9. Modifications and Updates</InternalHeader>
        <InternalText>
          9.1. Ace Trucking Academy may update or modify these Terms and
          Conditions at any time without prior notice. Any changes will be
          posted on the App, and your continued use of the App after such
          modifications constitutes your acknowledgment and acceptance of the
          updated terms.
        </InternalText>
        <InternalHeader>10. Contact Information</InternalHeader>
        <InternalText>
          10.1. For further inquiries regarding these Terms and Conditions,
          please reach us at{" "}
          <a href="mailto:info@acetruckingacademy.com">
            info@acetruckingacademy.com
          </a>{" "}
          or refer to our "Contact Us" page to select your preferred method of
          communication.
        </InternalText>
      </ContentContainer>

      <Footer />
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`
const ContentContainer = styled(Container)`
  margin-top: 98px;
`
const HeaderHolder = styled.div`
  padding: 98px 0;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--text-title-color);
`
const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--text-title-color);
  margin-top: 24px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-color);
  margin-top: 10px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--text-title-color);
`

const FooterContainer = styled.footer`
  width: 100%;
  padding-bottom: 36px;
`
const FooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
`

export default Terms
