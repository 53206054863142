import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import PublicHeader from "../../components/header/publicHeader"
import Footer from "./Footer"

function Contact() {
  let navigate = useNavigate()
  return (
    <MainContainer>
      <PublicHeader />
      <ContentContainer className="d-flex flex-column align-items-center">
        <HeaderHolder>
          <Title>CONTACT US</Title>
        </HeaderHolder>
        <InternalHeader></InternalHeader>
        <InternalText>
          Address:{" "}
          <a target="_blank" href="https://maps.app.goo.gl/5MsxPwvkANDSM1es8">
            1618 W Sam Houston Pkwy N, Houston, TX 77043, USA
          </a>
        </InternalText>
        <InternalText>Phone/Text: (832) 769-4523</InternalText>
        <InternalText>Email: <a target="_blank" href="mailto:info@acetruckingacademy.com">
        info@acetruckingacademy.com
          </a></InternalText>
        <InternalText>
          Hours of Operation: Weekdays 9am-6pm, holiday hours may vary
        </InternalText>
        <InternalHeader></InternalHeader>
        <InternalText>Social Media Links:</InternalText>
        <InternalText>
          Instagram:{" "}
          <a target="_blank" href="https://instagram.com/acetruckingacademy">
            @acetruckingacademy
          </a>
        </InternalText>
        <InternalText>TikTok:{" "}
          <a target="_blank" href="https://www.tiktok.com/@acetruckingacademy">
            @acetruckingacademy
          </a>
        </InternalText>
        <InternalText>
          Facebook:{" "}
          <a target="_blank" href="https://fb.me/ACETRUCKINGACADEMY">@acetruckingacademy</a>
        </InternalText>
        <InternalText>
          YouTube: ACE TRUCK KING -{" "}
          <a target="_blank" href="https://www.youtube.com/c/ACETruckKing">
            https://www.youtube.com/c/ACETruckKing
          </a>
        </InternalText>
        <InternalText>
          Website:{" "}
          <a target="_blank" href="https://acetruckingacademy.com/">
            https://acetruckingacademy.com/
          </a>
        </InternalText>
      </ContentContainer>

      <Footer />
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`
const ContentContainer = styled(Container)`
  margin-top: 98px;
`
const HeaderHolder = styled.div`
  padding: 98px 0;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--text-title-color);
`
const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--text-title-color);
  margin-top: 24px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-color);
  margin-top: 10px;

  //
  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--text-title-color);
`

const FooterContainer = styled.footer`
  width: 100%;
  padding-bottom: 36px;
`
const FooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
`

export default Contact
