import React, { useEffect, useState } from "react"
import styled from "styled-components"
import addImageIcon from "../../assets/add-image.png"
import trashIcon from "../../assets/checkbox.svg"
import failed from "../../assets/failed.svg"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import { useNavigate, useParams } from "react-router-dom"
import { main_admin_api } from "../../api/axios_admin_helper"
import Notification from "../../components/Notification"
import CustomButton from "../../components/button/CustomButton"
import AuthAdminHeader from "../../components/header/authAdminHeader"
import ResponseModal from "../../components/modal/ResponseModal"
import CountryData from "../../util/countries+states+cities.json"

function AdminApplicationDetail() {
  const [activeTab, setActiveTab] = useState("user_profile")

  const [isLoading, setIsloading] = useState(false)

  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [rejectionURL, setRejectionURL] = useState("")
  const [rejectionCats, setRejectionCats] = useState([])
  const [rejectionCat, setRejectionCat] = useState("")
  const [rejectionDescription, setRejectionDescription] = useState("")
  const [showDocResponseModal, setShowDocResponseModal] = useState(false)
  const [showDetailsResponseModal, setShowDetailsResponseModal] =
    useState(false)

  const [applicationData, setApplicationData] = useState({})
  const [documentData, setDocumentData] = useState({})
  const { isAdmitted, setIsAdmitted } = useState(false)
  const { userId } = useParams()

  const navigation = useNavigate()

  const _getRejectionCat = () => {
    setIsloading(true)

    main_admin_api
      .get(`/api/v1/rejection_categories/`)
      .then(({ data }) => {
        setIsloading(false)
        setRejectionCats(data?.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getUserApplication = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/student_applications/${userId}/`)
      .then(({ data }) => {
        setIsloading(false)
        setApplicationData(data)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _setApplicationStatus = id => {
    setIsloading(true)

    const payload = {
      status: "Approved"
    }
    main_admin_api
      .patch(`/admin/api/v1/student_applications/update_doc/${id}/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        setShowRejectionModal(false)
        _getUserApplication()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _replaceDocument = (id, newDoc) => {
    if (isLoading) return

    setIsloading(true)

    const formData = new FormData()
    formData.append("doc", newDoc, newDoc.name)
    formData.append("status", "Pending")

    main_admin_api
      .patch(`/admin/api/v1/student_applications/update_doc/${id}/`, formData)
      .then(({ data }) => {
        setIsloading(false)
        // Refresh the documents list
        _getUserApplication()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _setApplicationRejectStatus = id => {
    setIsloading(true)

    const payload = {
      status: "Rejected",
      reject_message: rejectionDescription,
      reject_message_category: rejectionCat
    }
    main_admin_api
      .patch(`/admin/api/v1/student_applications/update_doc/${id}/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        setShowRejectionModal(false)
        _getUserApplication()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _admitApplicant = () => {
    setIsloading(true)
    const payload = {
      is_student_admitted: true,
      user: userId
    }
    main_admin_api
      .patch(`/admin/api/v1/student_applications/admit/${userId}/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        // setIsAdmitted(true)
        setShowDocResponseModal(true)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  useEffect(() => {
    _getUserApplication()
    _getRejectionCat()
  }, [])

  return (
    <ContentContainer>
      <AuthAdminHeader />
      <MainContent>
        <ControledContainer>
          <Title>{`${applicationData?.first_name} ${applicationData?.last_name}`}</Title>
          <SubTitle
            href={`${applicationData?.email}`}
          >{`${applicationData?.email}`}</SubTitle>
          <TabNavContainer className="d-flex">
            <TabNavItem
              active={activeTab === "user_profile"}
              onClick={() => setActiveTab("user_profile")}
            >
              Basic Detail
            </TabNavItem>
            <TabNavItem
              active={activeTab === "documentation"}
              onClick={() => setActiveTab("documentation")}
            >
              Documentation
            </TabNavItem>
          </TabNavContainer>

          {activeTab === "user_profile" && (
            <>
              <Form>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Full name</FormLabel>
                  <FormControl
                    value={`${applicationData?.first_name} ${applicationData?.last_name}`}
                    type="text"
                    placeholder="Name"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Email address</FormLabel>
                  <FormControl
                    value={`${applicationData?.email}`}
                    type="email"
                    placeholder="Enter your email"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <LabelGroup>
                    <FormLabel noWidth>Bio</FormLabel>
                    <FormLabelDescription noWidth>
                      Write a short introduction.
                    </FormLabelDescription>
                  </LabelGroup>
                  <FormControl
                    value={`${applicationData?.profile?.bio}`}
                    type="text"
                    placeholder="introduction"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Street address</FormLabel>
                  <FormControl
                    value={`${applicationData?.profile?.street}`}
                    type="text"
                    placeholder="100 Smith Street"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>City</FormLabel>
                  <FormSelect value={`${applicationData?.profile?.city}`}>
                    <option>Select a city</option>
                    {applicationData?.profile?.state &&
                      CountryData.filter(
                        c => c.name === applicationData?.profile?.country
                      )?.[0]
                        ?.states?.filter(
                          s => s.name === applicationData?.profile?.state
                        )?.[0]
                        ?.cities.map(city => (
                          <option value={city.name}>{city.name}</option>
                        ))}
                  </FormSelect>
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>State / Province</FormLabel>
                  <FormSelect value={applicationData?.profile?.state}>
                    <option>Select a state</option>
                    {CountryData.filter(
                      c => c.name === applicationData?.profile?.country
                    )?.[0]?.states.map(state => (
                      <option value={state.name}>{state.name}</option>
                    ))}
                  </FormSelect>
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Country</FormLabel>
                  <FormSelect value={applicationData?.profile?.country}>
                    {CountryData.map(country => (
                      <option value={country.name}>{country.name}</option>
                    ))}
                  </FormSelect>
                </FormGroup>
              </Form>

              <ResponseModal
                isShow={showDetailsResponseModal}
                callback={() =>
                  setShowDetailsResponseModal(!showDetailsResponseModal)
                }
                title="Profile details saved"
                description="Lorem ipsum dolor sit amet consectetur. Fames volutpat at arcu molestie egestas tortor pharetra sit."
              />
            </>
          )}

          {activeTab === "documentation" && (
            <>
              {applicationData?.documents?.map(doc => (
                <CustomRow>
                  <UploadedDoc>
                    <UploadingFileContainer>
                      <Col className="col-2">
                        <UploadImg src={addImageIcon} />
                      </Col>
                      <Col className="col-8 pl-4">
                        <UploadName>{doc?.document_type?.title}</UploadName>
                        <UploadSize>{5000 / 1000} KB</UploadSize>
                      </Col>
                      <Col className="col-2">
                        <DeleteImg src={trashIcon} />
                      </Col>
                    </UploadingFileContainer>
                  </UploadedDoc>
                  <FileButtons>
                    <ActionButton>
                      <CustomButton
                        style={{ backgroundColor: "#EBF2FF", color: "#183464" }}
                        onClick={() => {
                          window.open(doc?.doc)
                        }}
                        title={"Open Document"}
                      />
                    </ActionButton>
                    {doc?.status == "Pending" ? (
                      <>
                        <ActionButton>
                          <CustomButton
                            style={{
                              backgroundColor: "#E5FCEE",
                              color: "#219653"
                            }}
                            onClick={() => {
                              _setApplicationStatus(doc?.id)
                            }}
                            title={"Approve"}
                          />
                        </ActionButton>
                        <ActionButton>
                          <CustomButton
                            style={{
                              backgroundColor: "#FEF3F2",
                              color: "#B42318"
                            }}
                            onClick={() => {
                              setShowRejectionModal(true)
                              setDocumentData(doc)
                            }}
                            title={"Decline"}
                          />
                        </ActionButton>
                      </>
                    ) : doc?.status == "Approved" ? (
                      <DeleteImg src={trashIcon} />
                    ) : (
                      <>
                        <ActionButton>
                          <CustomButton
                            style={{
                              backgroundColor: "#E5FCEE",
                              color: "#219653"
                            }}
                            onClick={() => {
                              document.getElementById("fileInput").click()
                            }}
                            title={"Replace document"}
                          />
                        </ActionButton>
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={e => {
                            const file = e.target.files[0]
                            if (file) {
                              _replaceDocument(doc?.id, file)
                            }
                          }}
                        />

                        <DeleteImg src={failed} />
                      </>
                    )}
                  </FileButtons>
                </CustomRow>
              ))}

              {isAdmitted ? (
                <></>
              ) : (
                <ActionButtons className="d-flex justify-content-start">
                  <ActionButton>
                    <CustomButton
                      onClick={_admitApplicant}
                      title={"Admit Applicant"}
                    />
                  </ActionButton>
                </ActionButtons>
              )}

              <ResponseModal
                isShow={showDocResponseModal}
                callback={() => {
                  setShowDocResponseModal(!showDocResponseModal)
                  navigation("/admin/students")
                }}
                title="Applicant Accepted"
                description="You will now see this applicant in your students page"
              />
            </>
          )}
        </ControledContainer>

        <Modal
          show={showRejectionModal}
          onHide={() => {
            setShowRejectionModal(false)
            setRejectionURL("")
            setRejectionCat("")
            setRejectionDescription("")
          }}
        >
          <Modal.Header closeButton>
            <div className="d-flex flex-column justify-content-center">
              <ModalTitle>
                Provide a reason for declining this document
              </ModalTitle>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="col-5">
                <RowText>Category*</RowText>
              </Col>
              <Col className="col-7">
                <Form.Select
                  value={rejectionCat}
                  onChange={e => setRejectionCat(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Choose a selection</option>
                  {rejectionCats?.map(r => (
                    <option value={r?.id}>{r?.name}</option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-2 pt-3 border-top">
              <Col className="col-5">
                <RowText>Description</RowText>
              </Col>
              <Col className="col-7">
                <FormControl
                  value={rejectionDescription}
                  onChange={e => setRejectionDescription(e.target.value)}
                  placeholder=""
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              title={"Decline Documentation"}
              onClick={() => {
                _setApplicationRejectStatus(documentData?.id)
              }}
            />
          </Modal.Footer>
        </Modal>
      </MainContent>
    </ContentContainer>
  )
}
const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const ControledContainer = styled(Container)`
  @media (min-width: 992px) {
    margin-left: 240px;
  }
`
const MainContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  @media (min-width: 992px) {
    margin-top: 32px;
  }
  margin-top: 15vh;
`

const TabNavContainer = styled.nav`
  height: 32px;
  border-bottom: 1px solid #eaecf0;
  margin-top: 24px;
`
const TabNavItem = styled.li`
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px 11px;
  gap: 8px;
  height: 32px;
  color: #667085;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  font-weight: ${props => (props.active ? "600" : "400")};
  border-bottom: 2px solid
    ${props => (props.active ? "#183464" : "transparent")};

  &:hover {
    font-weight: 600;
    border-bottom: 2px solid #183464;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`

const SubTitle = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #475467;
  margin-bottom: 0;
`

const SubDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 20px;
`

const FormLabel = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  width: ${props => !props.noWidth && "280px"};
  margin: 0;
  margin-right: ${props => !props.noWidth && "32px"};
`
const FormLabelDescription = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`
const LabelGroup = styled.div`
  width: 280px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
`

const FormControl = styled(Form.Control)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 512px;
`

const FormSelect = styled(Form.Select)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 512px;
`

const FormGroup = styled(Form.Group)`
  display: flex;
  flex-direction: row;
  padding: 24px 0;
  border-bottom: 1px solid #eaecf0;
`

const ActionButtons = styled.div`
  padding: 48px 10px;
  @media (max-width: 575.98px) {
    flex-direction: column;
  }
`
const ActionButton = styled.div`
  min-width: 100px;
  margin-left: 10px;
  align-self: center;
  @media (max-width: 575.98px) {
    margin-left: 0;
    min-width: 100%;
    margin-top: 10px;
  }
`
const DropZoneConatainer = styled.section`
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 126px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`

const DropZoneImg = styled.img`
  width: 40px;
  height: 40px;
`

const DropZoneTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  text-align: center;
`
const DropZoneText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  text-align: center;
  margin: 4px;
`

const ProfileDropZone = styled(Row)`
  width: 100%;
  max-width: 512px;
  margin-left: 10px;
`

const HeaderImg = styled.img`
  height: 64px;
  width: 64px;
`

const UploadingFileContainer = styled(Row)`
  padding: 16px;
  margin-left: 2px;
  margin-top: 16px;
  width: 100%;
  height: 75px;
  border-radius: 12px;
`

const UploadImg = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 100%;
`

const DeleteImg = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-left: 20px;
`
const UploadName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin: 0;
  //Stop overflow
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const UploadSize = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

const UploadedDoc = styled.div`
  width: 100%;
  max-width: 512px;
  height: 96px;
  background: #ffffff;
  border: 1px solid #183464;
  border-radius: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const FileButtons = styled.div`
  align-items: center;
  margin-left: 48px;
  display: flex;
`

const CustomRow = styled.div`
  width: 100%;
  display: flex;
`

const ModalTitle = styled(Modal.Title)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`

const ModalSubTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

const RowText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  or: #344054;
`

export default AdminApplicationDetail
