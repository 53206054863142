import React, { useContext, useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import styled from "styled-components"
import uploadIcon from "../../assets/upload.png"
import addImageIcon from "../../assets/add-image.png"
import alertIcon from "../../assets/alert-bg.png"
import trashIcon from "../../assets/trash.svg"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import CustomButton from "../../components/button/CustomButton"
import AuthHeader from "../../components/header/authHeader"
import CountryData from "../../util/countries+states+cities.json"
import { main_api } from "../../api/axios_helper"
import { getUserId } from "../../api/auth"
import Notification from "../../components/Notification"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import ResponseModal from "../../components/modal/ResponseModal"
import UserContext from "../../context/UserContext"

function UserSetting() {
  const [activeTab, setActiveTab] = useState("my_profile")

  const { user, onChange } = useContext(UserContext)

  const [isLoading, setIsloading] = useState(false)
  /**
   * FOR PROFILE SET
   */
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [userName, setUserName] = useState("")
  const [email, setEmail] = useState("")
  const [bio, setBio] = useState("")
  const [street, setStreet] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("Texas")
  const [country, setCounty] = useState("United States")
  const [profileImageUpload, setProfileImageUpload] = useState(null)
  /**
   * FOR PROFILE PASSWORD SET
   */
  const [password, setPassword] = useState("")
  const [new_password, setNewPassword] = useState("")
  const [confirm_new_password, setConfirmNewPassword] = useState("")

  const [userProfile, setUserProfile] = useState({})

  const [showPasswordResponseModal, setShowPasswordResponseModal] =
    useState(false)
  const [showDocResponseModal, setShowDocResponseModal] = useState(false)
  const [showActionNeededModal, setShowActionNeededModal] = useState(false)
  const [showDetailsResponseModal, setShowDetailsResponseModal] =
    useState(false)

  const [documentTypes, setDocumentTypes] = useState([])
  const [formDocumentData, setFormDocumentData] = useState([])

  const [isLearnersPermitDefault, setIsLearnersPermitDefault] = useState(true)

  const _getUserProfile = () => {
    if (isLoading) return

    setIsloading(true)
    main_api
      .get(`/api/v1/users/${getUserId()}/`)
      .then(({ data }) => {
        setIsloading(false)
        /**
        SET DATA FOR USER PROFILE SET
        */
        setFirstName(data?.first_name)
        setLastName(data?.last_name)
        setEmail(data?.email)
        setBio(data?.profile?.bio)
        setStreet(data?.profile?.street)
        setCity(data?.profile?.city)
        // setState(data?.profile?.state)
        setUserProfile(data)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getDocuments = () => {
    if (isLoading) return

    setIsloading(true)
    main_api
      .get(`/api/v1/document_types/`)
      .then(({ data }) => {
        setIsloading(false)
        //TODO: if it ever gets to pagination
        setDocumentTypes(data?.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _setUserProfile = () => {
    if (isLoading) return

    setIsloading(true)

    const formData = new FormData()
    formData.append("first_name", firstName)
    formData.append("last_name", lastName)
    formData.append("profile.bio", bio)
    formData.append("profile.street", street)
    formData.append("profile.city", city)
    formData.append("profile.state", state)
    formData.append("profile.country", country)
    if (profileImageUpload) {
      formData.append(
        "profile.photo",
        profileImageUpload,
        profileImageUpload.name
      )
    }

    main_api
      .patch(`/api/v1/users/${getUserId()}/`, formData)
      .then(({ data }) => {
        setIsloading(false)
        onChange(data)
        setShowDetailsResponseModal(true)
        // Notification.Success("Profile updated successfully")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _setUserDoc = () => {
    if (isLoading) return

    setIsloading(true)

    for (let index = 0; index < documentTypes.length; index++) {
      const element = documentTypes[index]

      const formData = new FormData()

      for (const [key, value] of Object.entries(formDocumentData)) {
        if (key.includes(`${element.id}+`) && value) {
          var currentKey = key.replace(`${element.id}+`, "")
          if (currentKey === "doc") {
            formData.append(currentKey, value, value.name)
          } else {
            formData.append(currentKey, value)
          }
        }
      }

      formData.append("document_type", element.id)
      if (formData.get("doc")) {
        main_api
          .post(`/api/v1/documents/`, formData)
          .then(({ data }) => {
            setIsloading(false)
            if (!isLearnersPermitDefault) {
              setShowActionNeededModal(true)
            } else {
              setShowDocResponseModal(true)
            }
            _getDocuments()
            // Notification.Success("Profile updated successfully")
          })
          .catch(error => {
            Notification.Error(
              error?.response?.data[Object.keys(error?.response?.data)[0]][0]
            )
            setIsloading(false)
          })
      } else {
        setIsloading(false)
      }
    }
  }

  const _setUserNewPassword = () => {
    if (isLoading) return
    if (new_password.length < 8) {
      return Notification.Success("Password too short")
    }
    if (new_password !== confirm_new_password) {
      return Notification.Success("Password does not match")
    }

    setIsloading(true)

    const formData = new FormData()
    formData.append("password_1", password)
    formData.append("password_2", new_password)

    main_api
      .post(`/api/v1/users/password/`, formData)
      .then(({ data }) => {
        setIsloading(false)
        setPassword("")
        setNewPassword("")
        setConfirmNewPassword("")

        setShowPasswordResponseModal(true)
        // Notification.Success("Profile updated successfully")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  useEffect(() => {
    _getUserProfile()
    _getDocuments()
  }, [])

  const isLicenseApproved = user?.drivers_license?.status == "Approved"

  const isCommercialApproved =
    user?.commercial_learners_permit?.status == "Approved"

  const isSocialApproved = user?.social_security_number?.status == "Approved"

  const isNotApproved =
    user?.drivers_license?.status != "Approved" &&
    user?.commercial_learners_permit?.status != "Approved" &&
    user?.social_security_number?.status != "Approved"

  return (
    <ContentContainer>
      <AuthHeader />
      <MainContent>
        <ControledContainer>
          <Title>Settings</Title>
          <TabNavContainer className="d-flex">
            <TabNavItem
              active={activeTab === "my_profile"}
              onClick={() => setActiveTab("my_profile")}
            >
              My profile
            </TabNavItem>
            <TabNavItem
              active={activeTab === "documentation"}
              onClick={() => setActiveTab("documentation")}
            >
              Documentation
            </TabNavItem>
            <TabNavItem
              active={activeTab === "change_password"}
              onClick={() => setActiveTab("change_password")}
            >
              Change password
            </TabNavItem>
          </TabNavContainer>

          {activeTab === "my_profile" && (
            <>
              <SubTitle>My Profile</SubTitle>
              <SubDescription>
                Update your photo and personal details here.
              </SubDescription>

              <Form>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>First name</FormLabel>
                  <FormControl
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    type="text"
                    placeholder="Name"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Last name</FormLabel>
                  <FormControl
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    type="text"
                    placeholder="Name"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Email address</FormLabel>
                  <FormControl
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter your email"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <LabelGroup>
                    <FormLabel noWidth>Your photo</FormLabel>
                    <FormLabelDescription noWidth>
                      This will be displayed on your profile.
                    </FormLabelDescription>
                  </LabelGroup>

                  <ProfileDropZone>
                    <Col className="col-2">
                      <HeaderImg
                        src={
                          userProfile?.profile?.photo
                            ? userProfile?.profile?.photo
                            : "/avatar.png"
                        }
                      />
                    </Col>
                    <Col className="col-10">
                      <Dropzone
                        maxFiles={1}
                        onDrop={files => {
                          setProfileImageUpload(files[0])
                        }}
                        accept={{ "image/png": [".png", ".jpg", ".jpeg"] }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <DropZoneConatainer>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="d-flex justify-content-center">
                                <DropZoneImg src={uploadIcon} />
                              </div>
                              <DropZoneText>
                                <DropZoneTitle>Click to upload </DropZoneTitle>
                                or drag and drop
                              </DropZoneText>
                              <DropZoneText>
                                PNG, JPG (max. 800x400px)
                              </DropZoneText>
                            </div>
                          </DropZoneConatainer>
                        )}
                      </Dropzone>
                      {profileImageUpload && (
                        <UploadingFileContainer>
                          <Col className="col-2">
                            <UploadImg src={addImageIcon} />
                          </Col>
                          <Col className="col-8 pl-4">
                            <UploadName>{profileImageUpload?.name}</UploadName>
                            <UploadSize>
                              {profileImageUpload?.size / 1000} KB
                            </UploadSize>
                          </Col>
                          <Col
                            onClick={() => setProfileImageUpload(null)}
                            className="col-2"
                          >
                            <DeleteImg src={trashIcon} />
                          </Col>
                        </UploadingFileContainer>
                      )}
                    </Col>
                  </ProfileDropZone>
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <LabelGroup>
                    <FormLabel noWidth>Bio</FormLabel>
                    <FormLabelDescription noWidth>
                      Write a short introduction.
                    </FormLabelDescription>
                  </LabelGroup>
                  <FormControl
                    value={bio}
                    onChange={e => setBio(e.target.value)}
                    type="text"
                    placeholder="introduction"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Street address</FormLabel>
                  <FormControl
                    value={street}
                    onChange={e => setStreet(e.target.value)}
                    type="text"
                    placeholder="100 Smith Street"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>City</FormLabel>
                  <FormSelect
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  >
                    <option>Select a city</option>
                    {state &&
                      CountryData.filter(c => c.name === country)?.[0]
                        ?.states?.filter(s => s.name === state)?.[0]
                        ?.cities.map(city => (
                          <option value={city.name}>{city.name}</option>
                        ))}
                  </FormSelect>
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>State / Province</FormLabel>
                  <FormSelect
                    value={state}
                    onChange={e => setState(e.target.value)}
                    disabled
                  >
                    <option>Select a state</option>
                    {CountryData.filter(
                      c => c.name === country
                    )?.[0]?.states.map(state => (
                      <option value={state.name}>{state.name}</option>
                    ))}
                  </FormSelect>
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Country</FormLabel>
                  <FormSelect value={country} disabled>
                    {CountryData.map(country => (
                      <option value={country.name}>{country.name}</option>
                    ))}
                  </FormSelect>
                </FormGroup>
                <ActionButtons className="d-flex justify-content-end">
                  <ActionButton>
                    <CustomButton light title={"Cancel"} />
                  </ActionButton>
                  <ActionButton>
                    <CustomButton onClick={_setUserProfile} title={"Save"} />
                  </ActionButton>
                </ActionButtons>
              </Form>

              <ResponseModal
                isShow={showDetailsResponseModal}
                callback={() =>
                  setShowDetailsResponseModal(!showDetailsResponseModal)
                }
                title="Profile details saved"
                description="Your profile details have been saved"
              />
            </>
          )}
          {activeTab === "documentation" && (
            <>
              <SubTitle>Documentation</SubTitle>
              <SubDescription>
                {isNotApproved
                  ? "Kindly upload the following documents"
                  : "Your document is approved and you can't perform any more options here"}
              </SubDescription>

              <Form>
                {documentTypes.map((dt, index) => (
                  <>
                    {dt.title?.includes("Learner") && (
                      <FormGroup
                        style={{
                          backgroundColor: ["Approved", "Pending"].includes(
                            dt?.last_student_upload?.status
                          )
                            ? "#00000010"
                            : "inherit"
                        }}
                        key={index}
                        controlId={`formBasicHave`}
                      >
                        <FormLabel>Do you have a {dt.title}?</FormLabel>
                        <Form.Check
                          type={"radio"}
                          id={`default-yes`}
                          label={`Yes`}
                          checked={isLearnersPermitDefault}
                          onChange={({ target }) => {
                            console.log("{{{{", target.value)
                            setIsLearnersPermitDefault(!isLearnersPermitDefault)
                          }}
                        />
                        <Form.Check
                          type={"radio"}
                          id={`default-no`}
                          label={`No`}
                          checked={!isLearnersPermitDefault}
                          style={{ marginLeft: 10 }}
                          onChange={({ target }) =>
                            setIsLearnersPermitDefault(!isLearnersPermitDefault)
                          }
                        />
                      </FormGroup>
                    )}
                    {dt.title?.includes("Learner") &&
                    !isLearnersPermitDefault ? (
                      <></>
                    ) : (
                      dt?.allowed_fields?.map((field, index) => (
                        <>
                          {field == "doc" ? (
                            <FormGroup
                              style={{
                                backgroundColor: [
                                  "Approved",
                                  "Pending"
                                ].includes(dt?.last_student_upload?.status)
                                  ? "#00000010"
                                  : "inherit",
                                pointerEvents: ["Approved", "Pending"].includes(
                                  dt?.last_student_upload?.status
                                )
                                  ? "none"
                                  : "auto"
                              }}
                              key={index}
                              controlId={`formBasic${field}`}
                            >
                              <FormLabel>
                                Upload your a copy of your {dt.title}
                              </FormLabel>
                              <ProfileDropZone>
                                <Dropzone
                                  onDrop={files => {
                                    setFormDocumentData({
                                      ...formDocumentData,
                                      [`${dt.id}+${field}`]: files[0]
                                    })
                                  }}
                                  accept={{
                                    "image/png": [".png", ".jpg", ".jpeg"],
                                    "application/pdf": [".pdf"]
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <DropZoneConatainer
                                      style={{
                                        backgroundColor: [
                                          "Approved",
                                          "Pending"
                                        ].includes(
                                          dt?.last_student_upload?.status
                                        )
                                          ? "#00000010"
                                          : "inherit"
                                      }}
                                    >
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="d-flex justify-content-center">
                                          <DropZoneImg src={uploadIcon} />
                                        </div>
                                        <DropZoneText>
                                          <DropZoneTitle>
                                            Click to upload{" "}
                                          </DropZoneTitle>
                                          or drag and drop
                                        </DropZoneText>
                                        <DropZoneText>
                                          PNG, JPG or PDF (max. 800x400px)
                                        </DropZoneText>
                                      </div>
                                    </DropZoneConatainer>
                                  )}
                                </Dropzone>
                                {formDocumentData?.[`${dt.id}+${field}`] && (
                                  <UploadingFileContainer>
                                    <Col className="col-2">
                                      <UploadImg src={addImageIcon} />
                                    </Col>
                                    <Col className="col-8 pl-4">
                                      <UploadName>
                                        {
                                          formDocumentData?.[
                                            `${dt.id}+${field}`
                                          ]?.name
                                        }
                                      </UploadName>
                                      <UploadSize>
                                        {formDocumentData?.[`${dt.id}+${field}`]
                                          ?.size / 1000}{" "}
                                        KB
                                      </UploadSize>
                                    </Col>
                                    <Col
                                      onClick={() =>
                                        setFormDocumentData({
                                          ...formDocumentData,
                                          [`${dt.id}+${field}`]: null
                                        })
                                      }
                                      className="col-2"
                                    >
                                      <DeleteImg src={trashIcon} />
                                    </Col>
                                  </UploadingFileContainer>
                                )}
                              </ProfileDropZone>
                            </FormGroup>
                          ) : (
                            <FormGroup
                              style={{
                                backgroundColor: [
                                  "Approved",
                                  "Pending"
                                ].includes(dt?.last_student_upload?.status)
                                  ? "#00000010"
                                  : "inherit",
                                pointerEvents: ["Approved", "Pending"].includes(
                                  dt?.last_student_upload?.status
                                )
                                  ? "none"
                                  : "auto"
                              }}
                              key={index}
                              controlId={`formBasic${field}`}
                            >
                              <FormLabel>
                                {dt.title} {field.split("_").join(" ")}
                              </FormLabel>
                              <FormControl
                                style={{
                                  backgroundColor: [
                                    "Approved",
                                    "Pending"
                                  ].includes(dt?.last_student_upload?.status)
                                    ? "#00000010"
                                    : "inherit"
                                }}
                                value={
                                  ["Approved", "Pending"].includes(
                                    dt?.last_student_upload?.status
                                  )
                                    ? dt?.last_student_upload?.[field]
                                    : formDocumentData?.[`${dt.id}+${field}`] ??
                                      null
                                }
                                onChange={({ target }) =>
                                  setFormDocumentData({
                                    ...formDocumentData,
                                    [`${dt.id}+${field}`]: target.value
                                  })
                                }
                                type={field.includes("date") ? "date" : "text"}
                                placeholder={`Enter ${field
                                  .split("_")
                                  .join(" ")} `}
                              />
                            </FormGroup>
                          )}
                        </>
                      ))
                    )}
                  </>
                ))}

                {isNotApproved && (
                  <ActionButtons className="d-flex justify-content-end">
                    <ActionButton>
                      <CustomButton light title={"Cancel"} />
                    </ActionButton>
                    <ActionButton>
                      <CustomButton onClick={_setUserDoc} title={"Save"} />
                    </ActionButton>
                  </ActionButtons>
                )}
              </Form>

              <ResponseModal
                isShow={showDocResponseModal}
                callback={() => setShowDocResponseModal(!showDocResponseModal)}
                title="Your information has been recieved"
                description="Your documents have been received, we will review documents within the next 24 hours. If any additional documents are needed we will reach out via email"
              />

              <ResponseModal
                isShow={showActionNeededModal}
                callback={() =>
                  setShowActionNeededModal(!showActionNeededModal)
                }
                customIcon={alertIcon}
                title="Action needed"
                description={`To start Behind the Wheel training, you need to have obtained a Texas Learner's Permit. Proceed to enrolment to get access to the practice quizzes to take the permit exam`}
                buttonText="Get Access"
              />
            </>
          )}
          {activeTab === "change_password" && (
            <>
              <SubTitle>Password</SubTitle>
              <SubDescription>
                Please enter your current password to change your password.
              </SubDescription>

              <Form>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Password</FormLabel>
                  <FormControl
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                    type="password"
                    placeholder="Current password"
                  />
                </FormGroup>
                <FormGroup controlId="formBasicEmail">
                  <FormLabel>New password</FormLabel>
                  <FormControl
                    value={new_password}
                    onChange={({ target }) => setNewPassword(target.value)}
                    type="password"
                    placeholder="New"
                  />
                </FormGroup>

                <FormGroup controlId="formBasicEmail">
                  <FormLabel>Confirm new password</FormLabel>
                  <FormControl
                    value={confirm_new_password}
                    onChange={({ target }) =>
                      setConfirmNewPassword(target.value)
                    }
                    type="password"
                    placeholder="Re enter"
                  />
                </FormGroup>

                <ActionButtons className="d-flex justify-content-end">
                  <ActionButton>
                    <CustomButton light title={"Cancel"} />
                  </ActionButton>
                  <ActionButton>
                    <CustomButton
                      onClick={_setUserNewPassword}
                      title={"Update password"}
                    />
                  </ActionButton>
                </ActionButtons>
              </Form>

              <ResponseModal
                isShow={showPasswordResponseModal}
                callback={() =>
                  setShowPasswordResponseModal(!showPasswordResponseModal)
                }
                title="Password changed"
                description="Lorem ipsum dolor sit amet consectetur. Fames volutpat at arcu molestie egestas tortor pharetra sit."
              />
            </>
          )}
        </ControledContainer>
      </MainContent>
    </ContentContainer>
  )
}
const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const ControledContainer = styled(Container)`
  @media (min-width: 992px) {
    margin-left: 240px;
  }
`
const MainContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  @media (min-width: 992px) {
    margin-top: 32px;
  }
  margin-top: 15vh;
`

const TabNavContainer = styled.nav`
  height: 32px;
  border-bottom: 1px solid #eaecf0;
  margin-top: 24px;
`
const TabNavItem = styled.li`
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px 11px;
  gap: 8px;
  height: 32px;
  color: #667085;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  font-weight: ${props => (props.active ? "600" : "400")};
  border-bottom: 2px solid
    ${props => (props.active ? "#183464" : "transparent")};

  &:hover {
    font-weight: 600;
    border-bottom: 2px solid #183464;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`

const SubTitle = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-top: 30px;
  margin-bottom: 0;
`

const SubDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 20px;
`

const FormLabel = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  width: ${props => !props.noWidth && "280px"};
  margin: 0;
  margin-right: ${props => !props.noWidth && "32px"};
`
const FormLabelDescription = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`
const LabelGroup = styled.div`
  width: 280px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
`

const FormControl = styled(Form.Control)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 512px;
`

const FormSelect = styled(Form.Select)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 512px;
`

const FormGroup = styled(Form.Group)`
  display: flex;
  flex-direction: row;
  padding: 24px 0;
  border-bottom: 1px solid #eaecf0;
`

const ActionButtons = styled.div`
  padding: 48px 10px;
  @media (max-width: 575.98px) {
    flex-direction: column;
  }
`
const ActionButton = styled.div`
  min-width: 100px;
  margin-left: 10px;
  @media (max-width: 575.98px) {
    margin-left: 0;
    min-width: 100%;
    margin-top: 10px;
  }
`
const DropZoneConatainer = styled.section`
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 126px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`

const DropZoneImg = styled.img`
  width: 40px;
  height: 40px;
`

const DropZoneTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  text-align: center;
`
const DropZoneText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  text-align: center;
  margin: 4px;
`

const ProfileDropZone = styled(Row)`
  width: 100%;
  max-width: 512px;
  margin-left: 10px;
`

const HeaderImg = styled.img`
  height: 64px;
  width: 64px;
`

const UploadingFileContainer = styled(Row)`
  padding: 16px;
  margin-left: 2px;
  margin-top: 16px;
  width: 100%;
  height: 75px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 12px;
`

const UploadImg = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 100%;
`

const DeleteImg = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
`
const UploadName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin: 0;
  //Stop overflow
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const UploadSize = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

export default UserSetting
