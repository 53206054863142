import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { setJWTToken, setUserId } from "../../api/auth"
import { basic_api } from "../../api/axios_helper"
import Notification from "../../components/Notification"
import keyIcon from "../../assets/key.png"

import Container from "react-bootstrap/Container"
import CustomButton from "../../components/button/CustomButton"
import Form from "react-bootstrap/Form"

function Forgot() {
  let navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [isLoading, setIsloading] = useState(false)

  const _requestLink = () => {
    if (isLoading) return
    const formData = new FormData()
    formData.append("email", email)
    setIsloading(true)
    basic_api
      .post("/api/v1/users/forgot_otp/", formData)
      .then(({ data }) => {
        setIsloading(false)
        Notification.Success("Request completed!")
        navigate("/forgot-password/mail-check", { state: { email } })
      })
      .catch(error => {
        console.log(error?.response?.data)
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]]
        )
        setIsloading(false)
      })
  }
  return (
    <ContentContainer>
      <CenterContentContainer>
        <div className="d-flex justify-content-center">
          <img src={keyIcon} width="60px" height="60px" />
        </div>
        <Title className="d-flex justify-content-center">
          Forgot password?
        </Title>
        <Text className="text-center">
          No worries, we'll send you reset instructions.
        </Text>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <FormLabel>Email</FormLabel>
            <FormControl
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              placeholder="Enter your email"
            />
          </Form.Group>

          <ButtonContainer>
            <CustomButton title={"Reset password"} onClick={_requestLink} />
          </ButtonContainer>
        </Form>
        <FooterTextContainer className="d-flex justify-content-center">
          <ForgotText onClick={() => navigate("/signin")}>
            Back to log in
          </ForgotText>
        </FooterTextContainer>
      </CenterContentContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled(Container)`
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 100px;
  justify-content: center;
`
const CenterContentContainer = styled.div`
  width: 360px;
`
const Title = styled.h1`
  margin-top: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: val(--text-title-color);
`
const Text = styled.p`
  margin-top: 12px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: val(--text-color);
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0;
`
const FormLabel = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`
const FormControl = styled(Form.Control)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const ForgotText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  margin-bottom: 24px;
  cursor: pointer;
`

const ForgotTextAlt = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-right: 4px;
  margin-bottom: 24px;
`

const FooterTextContainer = styled.div`
  margin-top: 32px;
`
const FormCheck = styled(Form.Check)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
`

export default Forgot
