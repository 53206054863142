const ChartIcon = ({ stroke }) => (
  <svg
    width="17"
    height="22"
    viewBox="0 0 17 22"
    fill="none"
    stroke={stroke ?? "current"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 21V8.5M8.5 21V1M1 21V13.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default ChartIcon
