import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { adminHasValidToken } from "../../api/adminauth"

const ProtectedAdminRoute = ({ Page }) => {
  const hasValidToken = adminHasValidToken()
  const [isExpanded, setIsExpanded] = useState(false)
  let navigate = useNavigate()
  let pathName = useLocation().pathname
  useEffect(() => {
    if (!hasValidToken) {
      navigate("/admin/signin")
    }
  }, [])
  return hasValidToken ? <Page /> : <></>
}

export default ProtectedAdminRoute
