import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import mailIcon from "../../assets/mail.png"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import CustomButton from "../../components/button/CustomButton"

import useDigitInput from "react-digit-input"
import { basic_api } from "../../api/axios_helper"
import Notification from "../../components/Notification"

function SignupMailCode() {
  let navigate = useNavigate()
  const [code, setCode] = useState("")
  const { state } = useLocation()
  const { email } = state
  const [isLoading, setIsloading] = useState(false)

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value: code,
    onChange: setCode
  })

  const _verify = () => {
    if (isLoading) return
    const formData = new FormData()
    formData.append("otp", code)
    formData.append("email", email)
    setIsloading(true)
    basic_api
      .post("/api/v1/users/verify_otp/", formData)
      .then(({ data }) => {
        Notification.Success("Successfully signed up")
        navigate("/signup/success")
      })
      .catch(error => {
        console.log(error?.response?.data)
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  return (
    <ContentContainer>
      <CenterContentContainer>
        <div className="d-flex justify-content-center">
          <img src={mailIcon} width="60px" height="60px" />
        </div>
        <Title className="d-flex justify-content-center">
          Check your email
        </Title>
        <Text className="text-center">
          We sent a OTP link to {email}
        </Text>
        <Form>
          <CodeGroup>
            <CodeInput inputMode="decimal" autoFocus {...digits[0]} />
            <CodeInput inputMode="decimal" {...digits[1]} />
            <CodeInput inputMode="decimal" {...digits[2]} />
            <CodeInput inputMode="decimal" {...digits[3]} />
          </CodeGroup>
          <ButtonContainer>
            <CustomButton title={"Verify email"} onClick={_verify} />
          </ButtonContainer>
        </Form>

        <FooterTextContainer className="d-flex justify-content-center">
          <ForgotTextAlt>Didn't receive the email?</ForgotTextAlt>
          <ForgotText onClick={() => navigate("/signin")}>
            Click to resend
          </ForgotText>
        </FooterTextContainer>
        <FooterTextContainer className="d-flex justify-content-center">
          <ForgotText onClick={() => navigate("/signin")}>
            Back to log in
          </ForgotText>
        </FooterTextContainer>
      </CenterContentContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled(Container)`
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 100px;
  justify-content: center;
`
const CenterContentContainer = styled.div`
  width: 360px;
`
const Title = styled.h1`
  margin-top: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: val(--text-title-color);
`
const Text = styled.p`
  margin-top: 12px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: val(--text-color);
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0;
`
const FormLabel = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`
const FormControl = styled(Form.Control)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const ForgotText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  margin-bottom: 24px;
  cursor: pointer;
`

const ForgotTextAlt = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-right: 4px;
  margin-bottom: 24px;
`

const FooterTextContainer = styled.div`
  margin-top: 32px;
`

const CodeGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`
const CodeInput = styled.input`
  width: 80px;
  height: 80px;
  background: #ffffff;
  border: 2px solid #183464;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #183464;
`

export default SignupMailCode
