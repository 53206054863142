import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { adminHasValidToken } from "../../api/adminauth"

const PublicOnlyAdminRoute = ({ Page }) => {
  const hasValidToken = adminHasValidToken()
  let navigate = useNavigate()
  useEffect(() => {
    if (hasValidToken) {
      navigate("/admin/home")
    }
  }, [])
  return !hasValidToken ? <Page /> : <></>
}
export default PublicOnlyAdminRoute
