import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import PublicHeader from "../../components/header/publicHeader"
import Footer from "./Footer"

function Privacy() {
  let navigate = useNavigate()
  return (
    <MainContainer>
      <PublicHeader />
      <ContentContainer className="d-flex flex-column align-items-center">
        <HeaderHolder>
          <Title>Privacy Policy</Title>
          <Text>
            {`Your privacy is important to us at Ace Trucking Academy. We respect your privacy regarding any information we may collect from you across our website.`}
          </Text>
        </HeaderHolder>
        <InternalHeader>1. Introduction</InternalHeader>
        <InternalText>
          At Ace Trucking Academy, we take your privacy seriously. Our privacy
          policy outlines how we collect, use, and safeguard your personal
          information.
        </InternalText>
        <InternalHeader>2. Information We Collect</InternalHeader>
        <InternalText>
          We collect personal information to verify your identity during the CDL
          enrollment process. This data includes details from your driver's
          license, such as your name, age, date of birth, address, and driver's
          license number. Additionally, we gather contact information, such as
          phone numbers and email addresses. These identity checks are mandated
          by federal regulations for accredited schools. The collected data
          undergoes review by both the Texas Workforce Commission (TWC) and the
          Federal Motor Carrier Safety Administration (FMCSA) as part of our
          accreditation requirements. TWC and FMCSA are pivotal in regulating
          and accrediting CDL schools, assuring the quality of our programs. We
          also acquire information from your five-year motor vehicle report to
          assess your eligibility for our courses. Emergency contact information
          is collected to facilitate responses to potential on-site emergencies.
          We may also request information about your family, career aspirations
          within the trucking industry, and job preferences to better match you
          with employment opportunities if you choose to pursue them.
        </InternalText>
        <InternalHeader>3. How We Use Your Information</InternalHeader>
        <InternalText>
          The data collected during the registration process serves several
          purposes, including identity verification, communication (both pre-
          and post-graduation), employment verifications, and service
          enhancements.
        </InternalText>
        <InternalHeader>4. Disclosure of Information</InternalHeader>
        <InternalText>
          In compliance with regulatory mandates, your personal information may
          be shared with prospective employers or government agencies for
          employment verification and regulatory compliance. We only share your
          information with third parties after obtaining your explicit
          permission. Occasionally, companies may contact us on your behalf to
          verify your training history for potential job opportunities. We will
          only release this information with your consent to assist in your job
          search.
        </InternalText>
        <InternalHeader>5. Data Security</InternalHeader>
        <InternalText>
          We implement industry-standard security measures, including encryption
          and secure data storage protocols, to safeguard your personal
          information from unauthorized access or disclosure.
        </InternalText>
        <InternalHeader>6. User Choices and Control</InternalHeader>
        <InternalText>
          While it is not possible to remove your data due to educational and
          employment reporting requirements, you have the option to opt out of
          specific communications, such as event invitations and job opening
          notifications.
        </InternalText>
        <InternalHeader>7. Third-Party Links</InternalHeader>
        <InternalText>
          Our website may include links to third-party websites, each with its
          own privacy policies. Examples include the Texas DPS system, the FMCSA
          website, and other educational resources. We are not responsible for
          the privacy practices of these external websites.
        </InternalText>
        <InternalHeader>8. Changes to Privacy Policy</InternalHeader>
        <InternalText>
          We may update this Privacy Policy to reflect changes in our data
          practices. Significant modifications will be communicated to you
          through our website or other appropriate channels.
        </InternalText>
        <InternalHeader>9. Contact Information</InternalHeader>
        <InternalText>
          If you have any questions or concerns regarding this Privacy Policy,
          please reach out to us at{" "}
          <a href="mailto:info@acetruckingacademy.com">
            info@acetruckingacademy.com
          </a>
          .
        </InternalText>
      </ContentContainer>
      
      <Footer/>
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`
const ContentContainer = styled(Container)`
  margin-top: 98px;
`
const HeaderHolder = styled.div`
  padding: 98px 0;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--text-title-color);
`
const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--text-title-color);
  margin-top: 24px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-color);
  margin-top: 10px;

  //
  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--text-title-color);
`

const FooterContainer = styled.footer`
  width: 100%;
  padding-bottom: 36px;
`
const FooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
`

export default Privacy
