const LogoutIcon = ({ stroke }) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    stroke={stroke ?? "current"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 12.1667L19 8M19 8L14 3.83333M19 8H7M7 0.5H5.8C4.11984 0.5 3.27976 0.5 2.63803 0.772484C2.07354 1.01217 1.6146 1.39462 1.32698 1.86502C1 2.3998 1 3.09987 1 4.5V11.5C1 12.9001 1 13.6002 1.32698 14.135C1.6146 14.6054 2.07354 14.9878 2.63803 15.2275C3.27976 15.5 4.11984 15.5 5.8 15.5H7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default LogoutIcon
