import React, { useEffect, useState } from "react"
import styled from "styled-components"
import addImageIcon from "../../assets/add-image.png"
import trashIcon from "../../assets/checkbox.svg"
import deleteIcon from "../../assets/delete.svg"
import failed from "../../assets/failed.svg"
import uploadIcon from "../../assets/upload.png"

import moment from "moment"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import Dropzone from "react-dropzone"
import { useParams } from "react-router-dom"
import { main_admin_api } from "../../api/axios_admin_helper"
import calendar from "../../assets/calendar.svg"
import houseIcon from "../../assets/house.png"
import time from "../../assets/time.png"
import Notification from "../../components/Notification"
import CustomButton from "../../components/button/CustomButton"
import AuthAdminHeader from "../../components/header/authAdminHeader"
import DeleteModal from "../../components/modal/DeleteModal"
import { calculateGradeAndGPAWithPoints } from "../../util"
import CountryData from "../../util/countries+states+cities.json"

//Todo: Split all conditional pages to separate pages to reduce api calls
function AdminStudentDetail() {
  const [activeTab, setActiveTab] = useState("user_profile")
  const [reportActiveTab, setReportActiveTab] = useState("attendance")

  const [isLoading, setIsloading] = useState(false)
  const [classInfoLoading, setClassInfoLoading] = useState(false)

  const [isExpelModal, setIsExpelModal] = useState(false)

  const [applicationData, setApplicationData] = useState({})
  const [reasonData, setReasonData] = useState([])
  const [documentTypes, setDocumentTypes] = useState([])
  const [applicationDataDoc, setApplicationDataDoc] = useState([])
  const [expelReason, setExpelReason] = useState("")
  const [expelBody, setExpelBody] = useState("")

  const [hasFlexUser, setHasFlexUser] = useState(false)
  const [flexQuiz, setFlexQuiz] = useState([])
  const [flexQuizList, setFlexQuizList] = useState([])
  const [selectedFlexQuiz, setSelectedFlexQuiz] = useState(null)

  const [attendanceData, setAttendanceData] = useState([])
  const [attendaceFormData, setAttendanceFormData] = useState({
    date: "",
    check_in_time: "",
    check_out_time: "",
    location: ""
  })

  const [yardRangeData, setYardRangeData] = useState({
    instructor: 0,
    truck: 0,
    skill: 0,
    grade: 0
  })

  const [roadData, setRoadData] = useState({
    instructor: 0,
    truck: 0,
    skill: 0,
    grade: 0,
    route: 0
  })

  const { userId } = useParams()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [userName, setUserName] = useState("")
  const [email, setEmail] = useState("")
  const [bio, setBio] = useState("")
  const [street, setStreet] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("Texas")
  const [country, setCounty] = useState("United States")
  const [classInfo, setClassInfo] = useState({
    class: null,
    date_enrolled: "",
    License_type: "",
    transmission_type: "",
    last_checked_in: ""
  })
  const [classes, setClasses] = useState([])
  const [klassApplicants, setKlassApplicants] = useState([])

  const [instructors, setInstructors] = useState([])
  const [yardAssessment, setYardAssessments] = useState([])
  const [roadAssessment, setRoadAssessments] = useState([])
  const [routes, setroutes] = useState([])
  const [grades, setgrades] = useState([])
  const [skills, setskills] = useState([])
  const [trucks, settrucks] = useState([])
  const [selectedDocument, setSelectedDocument] = useState(0)
  const [profileImageUpload, setProfileImageUpload] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const _getUserApplication = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/students/${userId}/`)
      .then(({ data }) => {
        setIsloading(false)
        setApplicationData(data)
        setFirstName(data?.first_name)
        setLastName(data?.last_name)
        setUserName(data?.username)
        setEmail(data?.email)
        setBio(data?.profile?.bio)
        setStreet(data?.profile?.street)
        setCity(data?.profile?.city)
        var dd = data?.documents.map(document => {
          var i = document.document_type
          return i
        })
        const recordsById = dd.reduce((ac, record) => {
          if (record.id) {
            return {
              ...ac,
              [record.id]: record
            }
          }
          return ac
        }, {})
        setApplicationDataDoc(recordsById)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getClassInfo = async () => {
    setIsloading(true)

    main_admin_api
      .get(`/classes/api/classes/`)
      .then(({ data }) => {
        data?.results.map(klass => {
          setClasses(prev => [
            ...prev,
            {
              id: klass.id,
              title: klass.title
            }
          ])
        })
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })

    main_admin_api
      .get(`/classes/api/classes/klass-applications/?applicant=${userId}`)
      .then(({ data }) => {
        if (data?.results?.length > 0) {
          setKlassApplicants(data?.results)
          setClassInfo({
            class: data?.results[0]?.class?.id
              ? data?.results[0]?.class?.id
              : classes[0]?.id,
            date_enrolled: data?.results[0]?.date_enrolled
              ? data?.results[0]?.date_enrolled
              : new Date(),
            License_type: data?.results[0]?.License_type
              ? data?.results[0]?.License_type
              : "A",
            transmission_type: data?.results[0]?.transmission_type
              ? data?.results[0]?.transmission_type
              : "automatic",
            last_checked_in: data?.results[0]?.last_checked_in
              ? data?.results[0]?.last_checked_in
              : new Date()
          })
        } else {
          setClassInfo({
            class: classes[0]?.id,
            date_enrolled: new Date(),
            License_type: "A",
            transmission_type: "automatic",
            last_checked_in: new Date()
          })
        }
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getReasons = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/reasons/`)
      .then(({ data }) => {
        setIsloading(false)
        setReasonData(data?.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getFlexUser = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/flexquiz/get_flexquiz_user/?user=${userId}`)
      .then(({ data }) => {
        setIsloading(false)
        setHasFlexUser(true)
        _getFlexQuiz()
        _getFlexQuizItem()
      })
      .catch(error => {
        setIsloading(false)
      })
  }

  const _getAttendance = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/student_attendance/?user=${userId}`)
      .then(({ data }) => {
        setIsloading(false)
        setAttendanceData(data.results)
      })
      .catch(error => {
        setIsloading(false)
      })
  }

  const _addFlexUser = () => {
    setIsloading(true)

    const payload = {
      user: userId
    }
    main_admin_api
      .post(`/admin/api/v1/flexquiz/create_flexquiz_user/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        _getFlexUser()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _addAttendance = () => {
    if (
      !attendaceFormData?.date ||
      !attendaceFormData?.check_in_time ||
      !attendaceFormData?.check_out_time ||
      !attendaceFormData?.location
    ) {
      Notification.Error("All fields are required")
      return
    }
    setIsloading(true)

    const payload = {
      user: userId,
      date: moment(attendaceFormData?.date).format("YYYY-MM-DD"),
      check_in_time: attendaceFormData?.check_in_time,
      check_out_time: attendaceFormData?.check_out_time,
      location: attendaceFormData?.location
    }

    main_admin_api
      .post(`/admin/api/v1/student_attendance/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        setAttendanceData([...attendanceData, data])
        setAttendanceFormData({
          date: "",
          check_in_time: "",
          check_out_time: "",
          location: ""
        })
        Notification.Success("Assessment added")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _addFlexQuizItem = () => {
    setIsloading(true)

    const payload = {
      user: userId,
      quiz_id: selectedFlexQuiz
    }
    main_admin_api
      .post(`/admin/api/v1/flexquiz/add_flexquiz/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        _getFlexQuizItem()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _getFlexQuizItem = () => {
    setIsloading(true)

    const payload = {
      user: userId,
      quiz_id: selectedFlexQuiz
    }
    main_admin_api
      .get(`/admin/api/v1/flexquiz/get_flexquiz/?user=${userId}`, payload)
      .then(({ data }) => {
        setIsloading(false)
        setFlexQuiz(data)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _getFlexQuiz = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/flexquiz/get_all_flexquiz/`)
      .then(({ data }) => {
        setIsloading(false)
        setFlexQuizList(data)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getDocuments = () => {
    if (isLoading) return

    setIsloading(true)
    main_admin_api
      .get(`/api/v1/document_types/`)
      .then(({ data }) => {
        setIsloading(false)
        setDocumentTypes(
          data?.results.map(document => {
            var i = {}
            i.title = document.title
            i.created_at = document.created_at
            i.id = document.id
            return i
          })
        )
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  useEffect(() => {
    _getUserApplication()
    _getClassInfo()
    _getReasons()
    _getFlexUser()
    _getAttendance()
    _getDocuments()
  }, [])
  const _setUserDoc = (file, dType) => {
    if (isLoading) return

    setIsloading(true)

    const formData = new FormData()

    formData.append("doc", file, file.name)
    formData.append("document_type", dType)
    formData.append("user", userId)

    main_admin_api
      .post(`/admin/api/v1/documents/`, formData)
      .then(({ data }) => {
        setIsloading(false)
        _getUserApplication()
        // Notification.Success("Profile updated successfully")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getInstructors = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/instructors/`)
      .then(({ data }) => {
        setIsloading(false)
        setInstructors(data.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _gettrucks = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/trucks/`)
      .then(({ data }) => {
        setIsloading(false)
        settrucks(data.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _getskills = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/skills/?limit=100`)
      .then(({ data }) => {
        setIsloading(false)
        setskills(data.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _getroutes = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/routes/`)
      .then(({ data }) => {
        setIsloading(false)
        setroutes(data.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _getgrades = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/grades/`)
      .then(({ data }) => {
        setIsloading(false)
        setgrades(data.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _getyardAssessments = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/assessment-yard/?user=${userId}`)
      .then(({ data }) => {
        setIsloading(false)
        setYardAssessments(data.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _getroadAssessments = () => {
    setIsloading(true)

    main_admin_api
      .get(`/admin/api/v1/assessment-road/?user=${userId}`)
      .then(({ data }) => {
        setIsloading(false)
        setRoadAssessments(data.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  //BTW calls
  useEffect(() => {
    _getInstructors()
    _gettrucks()
    _getskills()
    _getroutes()
    _getgrades()
    _getyardAssessments()
    _getroadAssessments()
  }, [])

  const _addYardAssessment = () => {
    setIsloading(true)
    const payload = {
      user: userId,
      instructor: yardRangeData?.instructor,
      truck: yardRangeData?.truck,
      skill: yardRangeData?.skill,
      grade: yardRangeData?.grade
    }
    main_admin_api
      .post(`/admin/api/v1/assessment-yard/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        setYardRangeData({
          instructor: "",
          truck: "",
          skill: "",
          grade: ""
        })
        _getyardAssessments()
        Notification.Success("Assessment added")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _addRoadAssessment = () => {
    setIsloading(true)
    const payload = {
      user: userId,
      skill: roadData?.skill,
      instructor: roadData?.instructor,
      truck: roadData?.truck,
      grade: roadData?.grade,
      route: roadData?.route
    }
    main_admin_api
      .post(`/admin/api/v1/assessment-road/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        setRoadData({
          instructor: "",
          truck: "",
          skill: "",
          grade: "",
          route: ""
        })
        _getroadAssessments()
        Notification.Success("Assessment added")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  //Expel a student
  const _expelStudent = () => {
    setIsloading(true)
    const payload = {
      reason: expelReason,
      user: userId,
      body: expelBody
    }
    main_admin_api
      .post(`/admin/api/v1/expel/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        setIsExpelModal(false)
        Notification.Success("Student Expelled")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _deleteDocument = () => {
    setIsloading(true)
    main_admin_api
      .delete(`/admin/api/v1/documents/${selectedDocument}/`)
      .then(({ data }) => {
        setIsloading(false)
        setShowDeleteModal(false)
        setSelectedDocument(0)
        _getUserApplication()
        _getDocuments()
        Notification.Success("Document Deleted")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setSelectedDocument(0)
        setIsloading(false)
      })
  }

  const _setUserProfile = () => {
    if (isLoading) return
    setIsloading(true)
    const formData = new FormData()
    formData.append("first_name", firstName)
    formData.append("last_name", lastName)
    formData.append("profile.bio", bio)
    formData.append("profile.street", street)
    formData.append("profile.city", city)
    formData.append("profile.state", state)
    formData.append("profile.country", country)
    main_admin_api
      .patch(`/admin/api/v1/students/${applicationData?.id}/`, formData)
      .then(({ data }) => {
        setIsloading(false)
        Notification.Success("Profile updated successfully")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _setClassInfo = () => {
    if (classInfoLoading) return
    setClassInfoLoading(true)

    const payload = {
      date_enrolled: moment(classInfo.date_enrolled).format(
        "YYYY-MM-DDT00:00:00Z"
      ),
      License_type: classInfo.License_type,
      transmission_type: classInfo.transmission_type,
      applicant: Number(userId),
      last_checked_in: moment(classInfo.last_checked_in).format(
        "YYYY-MM-DDT00:00:00Z"
      ),
      klass: Number(classInfo?.class)
    }

    if (klassApplicants.length > 0) {
      main_admin_api
        .post(`/classes/api/classes/klass-applications/`, payload)
        .then(({ data }) => {
          setClassInfoLoading(false)
          // _getClassInfo();
          Notification.Success("Class Info updated successfully")
        })
        .catch(error => {
          Notification.Error(
            error?.response?.data[Object.keys(error?.response?.data)[0]][0]
          )
          setClassInfoLoading(false)
        })
    } else {
      main_admin_api
        .put(`/classes/api/classes/klass-applications/`, payload)
        .then(({ data }) => {
          setClassInfoLoading(false)
          // _getClassInfo();
          Notification.Success("Class Info updated successfully")
        })
        .catch(error => {
          Notification.Error(
            error?.response?.data[Object.keys(error?.response?.data)[0]][0]
          )
          setClassInfoLoading(false)
        })
    }
  }

  return (
    <ContentContainer>
      <AuthAdminHeader />
      <MainContent>
        <ControledContainer>
          <StudentHeaderContainer>
            <LeftStudentHeader>
              <ProfileImage src={applicationData?.profile?.photo} />
              <div>
                <Title>{`${applicationData?.first_name} ${applicationData?.last_name}`}</Title>
                <SubTitle>{`Student ID: ${applicationData?.id}`}</SubTitle>
              </div>
            </LeftStudentHeader>
            <RightStudentHeader>
              <ExpelText onClick={() => setIsExpelModal(true)}>
                Expel Student
              </ExpelText>
            </RightStudentHeader>
          </StudentHeaderContainer>
          <TabNavContainer className="d-flex">
            <TabNavItem
              active={activeTab === "user_profile"}
              onClick={() => setActiveTab("user_profile")}
            >
              Basic Detail
            </TabNavItem>
            <TabNavItem
              active={activeTab === "report"}
              onClick={() => setActiveTab("report")}
            >
              Report
            </TabNavItem>
            <TabNavItem
              active={activeTab === "documentation"}
              onClick={() => setActiveTab("documentation")}
            >
              Documentation
            </TabNavItem>
          </TabNavContainer>

          {activeTab === "user_profile" && (
            <>
              <FormWithSide>
                <FormSide>
                  <FormSideTitle>Personal info</FormSideTitle>
                </FormSide>
                <FormBg>
                  <Form>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>Full name</FormLabel>
                      <FormControl
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        type="text"
                        placeholder="Name"
                      />
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>Email address</FormLabel>
                      <FormControl
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        placeholder="Enter your email"
                      />
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>Street address</FormLabel>
                      <FormControl
                        value={street}
                        onChange={e => setStreet(e.target.value)}
                        type="text"
                        placeholder="100 Smith Street"
                      />
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>City</FormLabel>
                      <FormSelect
                        value={city}
                        onChange={e => setCity(e.target.value)}
                      >
                        <option>Select a city</option>
                        {state &&
                          CountryData.filter(c => c.name === country)?.[0]
                            ?.states?.filter(s => s.name === state)?.[0]
                            ?.cities.map(city => (
                              <option value={city.name}>{city.name}</option>
                            ))}
                      </FormSelect>
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>State / Province</FormLabel>
                      <FormSelect
                        value={state}
                        onChange={e => setState(e.target.value)}
                        disabled
                      >
                        <option>Select a state</option>
                        {CountryData.filter(
                          c => c.name === country
                        )?.[0]?.states.map(state => (
                          <option value={state.name}>{state.name}</option>
                        ))}
                      </FormSelect>
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>Country</FormLabel>
                      <FormSelect value={country} disabled>
                        {CountryData.map(country => (
                          <option value={country.name}>{country.name}</option>
                        ))}
                      </FormSelect>
                    </FormGroup>
                    <ActionButtons className="d-flex justify-content-end">
                      <ActionButton>
                        <CustomButton
                          onClick={_setUserProfile}
                          title={"Save"}
                        />
                      </ActionButton>
                    </ActionButtons>
                  </Form>
                </FormBg>
              </FormWithSide>
              <FormWithSide>
                <FormSide>
                  <FormSideTitle>Class info</FormSideTitle>
                </FormSide>
                <FormBg>
                  <Form>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>Class</FormLabel>
                      <FormSelect
                        value={classInfo?.class}
                        onChange={e => {
                          setClassInfo({ ...classInfo, class: e.target.value })
                        }}
                      >
                        {classes.map(klass => (
                          <option value={klass.id}>{klass.title}</option>
                        ))}
                      </FormSelect>
                    </FormGroup>
                    <Form.Group>
                      <FormLabel>Date Enrolled</FormLabel>
                      <FormControl
                        style={{ height: "50px" }}
                        value={moment(classInfo?.date_enrolled)?.format(
                          "YYYY-MM-DD"
                        )}
                        type="date"
                        onChange={e => {
                          setClassInfo({
                            ...classInfo,
                            date_enrolled: e.target.value
                          })
                        }}
                        aria-label="attendance"
                      />
                    </Form.Group>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>License type</FormLabel>
                      <FormSelect
                        value={classInfo?.License_type}
                        onChange={e => {
                          setClassInfo({
                            ...classInfo,
                            License_type: e.target.value
                          })
                        }}
                      >
                        <option disabled selected>
                          Select License
                        </option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                      </FormSelect>
                    </FormGroup>
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>Transmission type</FormLabel>
                      <FormSelect
                        value={
                          classInfo?.transmission_type
                            ? classInfo?.transmission_type
                            : ""
                        }
                        onChange={e => {
                          setClassInfo({
                            ...classInfo,
                            transmission_type: e.target.value
                          })
                        }}
                      >
                        <option disabled selected>
                          Select Transmission
                        </option>
                        <option value="automatic">Automatic</option>
                        <option value="manual">Manual</option>
                      </FormSelect>
                    </FormGroup>
                    <FormLabel>Last Check-in</FormLabel>
                    <Form.Group>
                      <FormControl
                        style={{ height: "50px" }}
                        value={moment(classInfo?.last_checked_in)?.format(
                          "YYYY-MM-DD"
                        )}
                        type="date"
                        onChange={e => {
                          setClassInfo({
                            ...classInfo,
                            last_checked_in: e.target.value
                          })
                        }}
                        aria-label="attendance"
                      />
                    </Form.Group>
                    <ActionButtons className="d-flex justify-content-end">
                      <ActionButton>
                        <CustomButton onClick={_setClassInfo} title={"Save"} />
                      </ActionButton>
                    </ActionButtons>
                  </Form>
                </FormBg>
              </FormWithSide>
            </>
          )}

          {activeTab === "report" && (
            <>
              <TabNavContainer className="d-flex">
                <ReportTabNavItem
                  className={reportActiveTab === "attendance" && "active"}
                  onClick={() => setReportActiveTab("attendance")}
                >
                  Attendance
                </ReportTabNavItem>
                <ReportTabNavItem
                  className={reportActiveTab === "yard" && "active"}
                  onClick={() => setReportActiveTab("yard")}
                >
                  BTW - Yard Range
                </ReportTabNavItem>
                <ReportTabNavItem
                  className={reportActiveTab === "road" && "active"}
                  onClick={() => setReportActiveTab("road")}
                >
                  BTW - Road
                </ReportTabNavItem>
                <ReportTabNavItem
                  className={reportActiveTab === "test" && "active"}
                  onClick={() => setReportActiveTab("test")}
                >
                  Mock Test
                </ReportTabNavItem>
              </TabNavContainer>
              {reportActiveTab === "attendance" && (
                <>
                  <AttendanceItem>
                    <AttendanceItemSet>
                      <RowText>Assessment Date</RowText>
                      <Form.Group>
                        <FormControl
                          style={{ height: "50px" }}
                          value={attendaceFormData?.date}
                          type="date"
                          onChange={e => {
                            setAttendanceFormData({
                              ...attendaceFormData,
                              date: e.target.value
                            })
                          }}
                          aria-label="attendance"
                        />
                      </Form.Group>
                    </AttendanceItemSet>
                    <AttendanceItemSet>
                      <RowText>Check-in</RowText>
                      <Form.Group>
                        <FormControl
                          style={{ height: "50px" }}
                          value={attendaceFormData?.check_in_time}
                          type="time"
                          onChange={e => {
                            setAttendanceFormData({
                              ...attendaceFormData,
                              check_in_time: e.target.value
                            })
                          }}
                          aria-label="attendance"
                        />
                      </Form.Group>
                    </AttendanceItemSet>
                    <AttendanceItemSet>
                      <RowText>Check-out</RowText>
                      <Form.Group>
                        <FormControl
                          style={{ height: "50px" }}
                          value={attendaceFormData?.check_out_time}
                          type="time"
                          onChange={e => {
                            setAttendanceFormData({
                              ...attendaceFormData,
                              check_out_time: e.target.value
                            })
                          }}
                          aria-label="attendance"
                        />
                      </Form.Group>
                    </AttendanceItemSet>
                    <AttendanceItemSet>
                      <RowText>Location</RowText>
                      <Form.Group>
                        <FormControl
                          style={{ height: "50px" }}
                          value={
                            attendaceFormData?.location
                              ? attendaceFormData?.location
                              : ""
                          }
                          type="text"
                          onChange={e => {
                            setAttendanceFormData({
                              ...attendaceFormData,
                              location: e.target.value
                            })
                          }}
                          aria-label="attendance"
                        />
                      </Form.Group>
                    </AttendanceItemSet>
                  </AttendanceItem>
                  <AttendanceButtonItem>
                    <AttendanceItemButton>
                      <CustomButton
                        style={{
                          backgroundColor: "#183464",
                          borderColor: "#183464"
                        }}
                        title={"New Entry"}
                        onClick={_addAttendance}
                      />
                    </AttendanceItemButton>
                  </AttendanceButtonItem>
                  <hr />
                  {attendanceData?.map(attendance => {
                    return (
                      <>
                        <AttendanceItem>
                          <AttendanceItemSet>
                            <RowText>Assessment Date</RowText>
                            <CalendarContainer className="d-flex align-items-center">
                              <CalendarIcon src={calendar} />
                              <CalendarDate>
                                {moment(attendance?.date)?.format(
                                  "MMMM D, YYYY"
                                )}
                              </CalendarDate>
                            </CalendarContainer>
                          </AttendanceItemSet>
                          <AttendanceItemSet>
                            <RowText>Check-in</RowText>
                            <CalendarContainer className="d-flex align-items-center">
                              <CalendarIcon src={time} />
                              <CalendarDate>
                                {attendance?.check_in_time}
                              </CalendarDate>
                            </CalendarContainer>
                          </AttendanceItemSet>
                          <AttendanceItemSet>
                            <RowText>Check-out</RowText>
                            <CalendarContainer className="d-flex align-items-center">
                              <CalendarIcon src={time} />
                              <CalendarDate>
                                {attendance?.check_out_time}
                              </CalendarDate>
                            </CalendarContainer>
                          </AttendanceItemSet>
                          <AttendanceItemSet>
                            <RowText>Location</RowText>
                            <CalendarContainer className="d-flex align-items-center">
                              <CalendarDate>
                                {attendance?.location}
                              </CalendarDate>
                            </CalendarContainer>
                          </AttendanceItemSet>
                        </AttendanceItem>
                      </>
                    )
                  })}
                </>
              )}
              {reportActiveTab === "test" && (
                <>
                  {flexQuiz.map((q, index) => (
                    <BTWGroupContainer key={index}>
                      <div>
                        <RowText>Quiz</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            style={{ height: "50px" }}
                            disabled
                            value={q.quiz_id}
                            aria-label="Quiz"
                          >
                            {flexQuizList?.map(reason => (
                              <option value={reason.quiz_id}>
                                {reason.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </div>
                      <div>
                        <RowText>Status</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            style={{ height: "50px" }}
                            disabled
                            value={q.quiz_id}
                            aria-label="Quiz"
                          >
                            <option value={q.quiz_id}>{q.status}</option>
                          </Form.Select>
                        </BTWInputContainer>
                      </div>
                      <div>
                        <RowText>Grade</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            style={{ height: "50px" }}
                            disabled
                            value={q.quiz_id}
                            aria-label="Quiz"
                          >
                            <option value={q.quiz_id}>
                              {
                                calculateGradeAndGPAWithPoints(
                                  q.points,
                                  q?.status
                                )?.grade
                              }
                            </option>
                          </Form.Select>
                        </BTWInputContainer>
                      </div>

                      {/* <BTWInputContainer>
                          <CustomButton
                            style={{
                              backgroundColor: "#D92D20",
                              borderColor: "#D92D20",
                              width: "150px"
                            }}
                            title={"Remove"}
                            onClick={() => {}}
                          />
                        </BTWInputContainer> */}
                    </BTWGroupContainer>
                  ))}

                  {hasFlexUser ? (
                    <BTWGroupContainer>
                      <div>
                        <RowText>Quiz</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            style={{ height: "50px" }}
                            value={selectedFlexQuiz}
                            onChange={e => setSelectedFlexQuiz(e.target.value)}
                            aria-label="Quiz"
                          >
                            <option>Select Quiz</option>
                            {flexQuizList?.map(reason => (
                              <option value={reason.quiz_id}>
                                {reason.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </div>

                      <BTWInputContainer>
                        <CustomButton
                          style={{
                            backgroundColor: "#183464",
                            borderColor: "#183464"
                          }}
                          title={"Add New Entry"}
                          onClick={_addFlexQuizItem}
                        />
                      </BTWInputContainer>
                    </BTWGroupContainer>
                  ) : (
                    <BTWInputContainer>
                      <CustomButton
                        style={{
                          backgroundColor: "#183464",
                          borderColor: "#183464"
                        }}
                        title={"Create FlexQuiz Profile"}
                        onClick={_addFlexUser}
                      />
                    </BTWInputContainer>
                  )}
                </>
              )}

              {reportActiveTab === "yard" && (
                <>
                  <BTWGroupContainer>
                    <BTWItemContainer>
                      <RowText>Truck Number</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          aria-label="Quiz"
                          value={yardRangeData?.truck}
                          onChange={e => {
                            setYardRangeData({
                              ...yardRangeData,
                              truck: e.target.value
                            })
                          }}
                        >
                          <option>Choose truck</option>
                          {trucks.map((truck, index) => (
                            <option key={index} value={truck.id}>
                              {truck.name}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                    <BTWItemContainer>
                      <RowText>Instructor</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          value={yardRangeData?.instructor}
                          aria-label="Quiz"
                          onChange={e => {
                            setYardRangeData({
                              ...yardRangeData,
                              instructor: e.target.value
                            })
                          }}
                        >
                          <option>Choose instructor</option>
                          {instructors.map((instructor, index) => (
                            <option key={index} value={instructor.id}>
                              {instructor.email}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                    <BTWItemContainer>
                      <RowText>Choose a skill</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          aria-label="Quiz"
                          value={yardRangeData?.skill}
                          onChange={e => {
                            setYardRangeData({
                              ...yardRangeData,
                              skill: e.target.value
                            })
                          }}
                        >
                          <option>Choose skill</option>
                          {skills.map((skill, index) => (
                            <option key={index} value={skill.id}>
                              {skill.name}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                    <BTWItemContainer>
                      <RowText>Enter a grade</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          aria-label="Quiz"
                          value={yardRangeData?.grade}
                          onChange={e => {
                            setYardRangeData({
                              ...yardRangeData,
                              grade: e.target.value
                            })
                          }}
                        >
                          <option>Choose grade</option>
                          {grades.map((grade, index) => (
                            <option key={index} value={grade.id}>
                              {grade.name}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                  </BTWGroupContainer>
                  {yardRangeData?.grade > 0 &&
                    yardRangeData?.truck > 0 &&
                    yardRangeData?.instructor > 0 &&
                    yardRangeData?.skill && (
                      <AttendanceButtonItem>
                        <AttendanceItemButton
                          style={{
                            marginRight: "46px"
                          }}
                        >
                          <CustomButton
                            style={{
                              backgroundColor: "#183464",
                              borderColor: "#183464",
                              marginTop: "10px"
                            }}
                            title={"New Entry"}
                            onClick={_addYardAssessment}
                          />
                        </AttendanceItemButton>
                      </AttendanceButtonItem>
                    )}
                  <hr />
                  {yardAssessment.map((q, index) => (
                    <BTWGroupContainer key={index}>
                      <BTWItemContainer>
                        <RowText>Truck Number</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.truck}
                            aria-label="Quiz"
                          >
                            {trucks.map((truck, index) => (
                              <option key={index} value={truck.id}>
                                {truck.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                      <BTWItemContainer>
                        <RowText>Instructor</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.instructor}
                            aria-label="Quiz"
                          >
                            {instructors.map((instructor, index) => (
                              <option key={index} value={instructor.id}>
                                {instructor.email}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                      <BTWItemContainer>
                        <RowText>Choose a skill</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.skill}
                            aria-label="Quiz"
                          >
                            {skills.map((skills, index) => (
                              <option key={index} value={skills.id}>
                                {skills.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                      <BTWItemContainer>
                        <RowText>Enter a grade</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.grade}
                            aria-label="Quiz"
                          >
                            {grades.map((grades, index) => (
                              <option key={index} value={grades.id}>
                                {grades.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                    </BTWGroupContainer>
                  ))}
                </>
              )}
              {reportActiveTab === "road" && (
                <>
                  <BTWGroupContainer>
                    <BTWItemContainer>
                      <RowText>Truck Number</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          aria-label="Quiz"
                          value={roadData.truck}
                          onChange={e => {
                            setRoadData({ ...roadData, truck: e.target.value })
                          }}
                        >
                          <option>Choose truck</option>
                          {trucks.map((truck, index) => (
                            <option key={index} value={truck.id}>
                              {truck.name}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                    <BTWItemContainer>
                      <RowText>Instructor</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          value={roadData.instructor}
                          aria-label="Quiz"
                          onChange={e => {
                            setRoadData({
                              ...roadData,
                              instructor: e.target.value
                            })
                          }}
                        >
                          <option>Choose instructor</option>
                          {instructors.map((instructor, index) => (
                            <option key={index} value={instructor.id}>
                              {instructor.email}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                    <BTWItemContainer>
                      <RowText>Choose a skill</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          aria-label="Quiz"
                          value={roadData.skill}
                          onChange={e => {
                            setRoadData({ ...roadData, skill: e.target.value })
                          }}
                        >
                          <option>Choose skill</option>
                          {skills.map((skill, index) => (
                            <option key={index} value={skill.id}>
                              {skill.name}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                    <BTWItemContainer>
                      <RowText>Enter a grade</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          aria-label="Quiz"
                          value={roadData.grade}
                          onChange={e => {
                            setRoadData({ ...roadData, grade: e.target.value })
                          }}
                        >
                          <option>Choose grade</option>
                          {grades.map((grade, index) => (
                            <option key={index} value={grade.id}>
                              {grade.name}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                    <BTWItemContainer>
                      <RowText>Route Number</RowText>
                      <BTWInputContainer>
                        <Form.Select
                          aria-label="Quiz"
                          value={roadData.route}
                          onChange={e => {
                            setRoadData({ ...roadData, route: e.target.value })
                          }}
                        >
                          <option>Choose route</option>
                          {routes.map((route, index) => (
                            <option key={index} value={route.id}>
                              {route.name}
                            </option>
                          ))}
                        </Form.Select>
                      </BTWInputContainer>
                    </BTWItemContainer>
                    <BTWItemContainer>
                      {roadData.grade > 0 &&
                        roadData.truck > 0 &&
                        roadData.instructor > 0 &&
                        roadData.skill > 0 &&
                        roadData.route > 0 && (
                          <AttendanceButtonItem>
                            <AttendanceItemButton>
                              <CustomButton
                                style={{
                                  backgroundColor: "#183464",
                                  borderColor: "#183464",
                                  marginTop: "10px"
                                }}
                                title={"New Entry"}
                                onClick={_addRoadAssessment}
                              />
                            </AttendanceItemButton>
                          </AttendanceButtonItem>
                        )}
                    </BTWItemContainer>
                  </BTWGroupContainer>
                  <hr />
                  {roadAssessment.map((q, index) => (
                    <BTWGroupContainer key={index}>
                      <BTWItemContainer>
                        <RowText>Truck Number</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.truck}
                            aria-label="Quiz"
                          >
                            {trucks.map((truck, index) => (
                              <option key={index} value={truck.id}>
                                {truck.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                      <BTWItemContainer>
                        <RowText>Instructor</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.instructor}
                            aria-label="Quiz"
                          >
                            {instructors.map((instructor, index) => (
                              <option key={index} value={instructor.id}>
                                {instructor.email}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                      <BTWItemContainer>
                        <RowText>Choose a skill</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.skill}
                            aria-label="Quiz"
                          >
                            {skills.map((skills, index) => (
                              <option key={index} value={skills.id}>
                                {skills.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                      <BTWItemContainer>
                        <RowText>Enter a grade</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.grade}
                            aria-label="Quiz"
                          >
                            {grades.map((grades, index) => (
                              <option key={index} value={grades.id}>
                                {grades.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                      <BTWItemContainer>
                        <RowText>Route Number</RowText>
                        <BTWInputContainer>
                          <Form.Select
                            disabled
                            value={q.grade}
                            aria-label="Quiz"
                          >
                            {grades.map((grades, index) => (
                              <option key={index} value={grades.id}>
                                {grades.name}
                              </option>
                            ))}
                          </Form.Select>
                        </BTWInputContainer>
                      </BTWItemContainer>
                    </BTWGroupContainer>
                  ))}
                </>
              )}
            </>
          )}

          {activeTab === "documentation" && (
            <>
              <DeleteModal
                description="Are you sure Want to delete this document"
                title="Delete Document"
                callback={() => {
                  _deleteDocument()
                }}
                handleClosecallback={() => setSelectedDocument(0)}
                isShow={showDeleteModal}
              />
              {applicationData?.documents?.map(doc => (
                <CustomRow>
                  <UploadedDoc>
                    <UploadingFileContainer>
                      <Col className="col-2">
                        <UploadImg src={addImageIcon} />
                      </Col>
                      <Col className="col-8 pl-4">
                        <UploadName>{doc?.document_type?.title}</UploadName>
                        <UploadSize>{5000 / 1000} KB</UploadSize>
                      </Col>
                      <Col className="col-2">
                        <DeleteImg src={trashIcon} />
                      </Col>
                    </UploadingFileContainer>
                  </UploadedDoc>
                  <FileButtons>
                    <ActionButton>
                      <CustomButton
                        style={{ backgroundColor: "#EBF2FF", color: "#183464" }}
                        onClick={() => {
                          window.open(doc?.doc)
                        }}
                        title={"Open Document"}
                      />
                    </ActionButton>
                    {doc?.status == "Approved" ? (
                      <DeleteImg src={trashIcon} />
                    ) : (
                      <DeleteImg src={failed} />
                    )}
                    <DeleteImg
                      src={deleteIcon}
                      onClick={() => {
                        setShowDeleteModal(true)
                        setSelectedDocument(doc?.id)
                      }}
                    />
                  </FileButtons>
                </CustomRow>
              ))}

              {documentTypes
                .filter(item => {
                  const isDuplicated = applicationDataDoc[item.id]
                  return !isDuplicated
                })
                .map(x => (
                  <Dropzone
                    maxFiles={1}
                    onDrop={files => {
                      _setUserDoc(files[0], x.id)
                    }}
                    accept={{
                      "image/png": [".png", ".jpg", ".jpeg"],
                      "application/pdf": [".pdf"]
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <DropZoneConatainer>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="d-flex justify-content-center">
                            <DropZoneImg src={uploadIcon} />
                          </div>
                          <DropZoneText>
                            <DropZoneTitle>Click to upload </DropZoneTitle>
                            {x.title}
                          </DropZoneText>
                          <DropZoneText>PNG, JPG (max. 800x400px)</DropZoneText>
                        </div>
                      </DropZoneConatainer>
                    )}
                  </Dropzone>
                ))}
            </>
          )}
        </ControledContainer>

        <Modal show={isExpelModal} onHide={() => setIsExpelModal(false)}>
          <Modal.Header closeButton>
            <ModalLeftIconHolder>
              <ModalLeftIcon src={houseIcon} />
            </ModalLeftIconHolder>
            <div className="d-flex flex-column justify-content-center">
              <ModalTitle>{`Expel ${applicationData?.first_name} ${applicationData?.last_name}`}</ModalTitle>
              <ModalSubTitle>
                Step Lorem ipsum dolor sit amet consectetur. Fames volutpat at
                arcu molestie egestas tortor pharetra sit.
              </ModalSubTitle>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row className="mt-2 pt-3 border-top">
              <Col className="col-5">
                <RowText>Reason for Expulsion*</RowText>
              </Col>
              <Col className="col-7">
                <Form.Select
                  value={expelReason}
                  onChange={e => setExpelReason(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Select reason</option>
                  {reasonData?.map(reason => (
                    <option value={reason.id}>{reason.name}</option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            <Row className="mt-2 pt-3 border-top">
              <Col className="col-5">
                <RowText>Additional Information*</RowText>
              </Col>
              <Col className="col-7">
                <Form.Group controlId="formBasicEmail">
                  <FormControl
                    value={expelBody}
                    onChange={e => setExpelBody(e.target.value)}
                    type="text"
                    placeholder="Write a few sentences about the expulsion..."
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              style={{ backgroundColor: "#D92D20", borderColor: "#D92D20" }}
              title={"Expel student"}
              onClick={() => {
                _expelStudent()
              }}
            />
          </Modal.Footer>
        </Modal>
      </MainContent>
    </ContentContainer>
  )
}
const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const ControledContainer = styled(Container)`
  @media (min-width: 992px) {
    margin-left: 240px;
  }
`
const MainContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  @media (min-width: 992px) {
    margin-top: 32px;
  }
  margin-top: 15vh;
`

const TabNavContainer = styled.nav`
  height: 32px;
  border-bottom: 1px solid #eaecf0;
  margin-top: 24px;
  margin-bottom: 32px;
`
const TabNavItem = styled.li`
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px 11px;
  gap: 8px;
  height: 32px;
  color: #667085;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  font-weight: ${props => (props.active ? "600" : "400")};
  border-bottom: 2px solid
    ${props => (props.active ? "#183464" : "transparent")};

  &:hover {
    font-weight: 600;
    border-bottom: 2px solid #183464;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`

const SubTitle = styled.h3`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const FormLabel = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  width: ${props => !props.noWidth && "280px"};
  margin: 0;
  margin-right: ${props => !props.noWidth && "32px"};
`
const FormLabelDescription = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`
const LabelGroup = styled.div`
  width: 280px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
`

const FormControl = styled(Form.Control)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 512px;
`

const FormSelect = styled(Form.Select)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 512px;
`

const FormGroup = styled(Form.Group)`
  padding: 24px 0;
  width: 464px;
  border-bottom: 1px solid #eaecf0;
`

const ActionButton = styled.div`
  min-width: 100px;
  margin-left: 10px;
  align-self: center;
  @media (max-width: 575.98px) {
    margin-left: 0;
    min-width: 100%;
    margin-top: 10px;
  }
`

const ActionButtons = styled.div`
  padding: 48px 10px;
  @media (max-width: 575.98px) {
    flex-direction: column;
  }
`
const UploadingFileContainer = styled(Row)`
  padding: 16px;
  margin-left: 2px;
  margin-top: 16px;
  width: 100%;
  height: 75px;
  border-radius: 12px;
`

const UploadImg = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 100%;
`

const DeleteImg = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-left: 20px;
`
const UploadName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin: 0;
  //Stop overflow
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const UploadSize = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

const UploadedDoc = styled.div`
  width: 100%;
  max-width: 512px;
  height: 96px;
  background: #ffffff;
  border: 1px solid #183464;
  border-radius: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const FileButtons = styled.div`
  align-items: center;
  margin-left: 48px;
  display: flex;
`

const CustomRow = styled.div`
  width: 100%;
  display: flex;
`
const StudentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px;
`
const LeftStudentHeader = styled.div`
  display: flex;
  flex-direction: row;
`
const ProfileImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 200px;
  margin-right: 20px;
`
const RightStudentHeader = styled.div``
const ExpelText = styled.p`
  padding: 10px 16px;
  color: #eb5757;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
`

const FormWithSide = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`
const FormSide = styled.div`
  width: 280px;
`
const FormSideTitle = styled.p`
  width: 280px;
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`
const FormBg = styled.div`
  padding: 24px;
  border-radius: 12px;
  background: #00000005;
`

const CalendarContainer = styled.div`
  width: 250px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 11.67px 18.67px;
`
const CalendarIcon = styled.img`
  width: 15px;
  height: 100%;
  margin-right: 10px;
`
const CalendarDate = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`

const ModalTitle = styled(Modal.Title)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`
const ModalSubTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

const ModalLeftIconHolder = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  padding: 15px 15px;
  margin-right: 16px;
`
const ModalLeftIcon = styled.img`
  height: 18px;
  width: 20px;
`

const RowText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  or: #344054;
`

const ReportTabNavItem = styled.li`
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  height: 32px;
  color: #667085;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  font-weight: 400;
  background: transparent;
  &.active {
    background: #ebf2ff;
    font-weight: 600;
  }

  &:hover {
    font-weight: 600;
    background: #ebf2ff;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`

const AttendanceItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 32px; 0;
`

const AttendanceButtonItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
`

const AttendanceItemSet = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
`

const AttendanceItemButton = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  justify-content: center;
`

const BTWInputContainer = styled.div`
  width: 244px;
  height: 40px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-right: 15px;
`
const BTWGroupContainer = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
`
const BTWItemContainer = styled.div`
  margin: 3px 15px;
`

const DropZoneConatainer = styled.section`
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 126px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`

const DropZoneImg = styled.img`
  width: 40px;
  height: 40px;
`

const DropZoneTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  text-align: center;
`
const DropZoneText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  text-align: center;
  margin: 4px;
`

export default AdminStudentDetail
