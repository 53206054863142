import "bootstrap/dist/css/bootstrap.min.css"
import { useState } from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import NoPage from "./NoPage"
import ProtectedAdminRoute from "./components/RoutesWrapper/ProtectedAdminRoute"
import ProtectedRoute from "./components/RoutesWrapper/ProtectedRoute"
import PublicOnlyAdminRoute from "./components/RoutesWrapper/PublicOnlyAdminRoute"
import PublicOnlyRoute from "./components/RoutesWrapper/PublicOnlyRoute"
import UserContext from "./context/UserContext"
import AdminHome from "./pages/Admin"
import AdminApplicationDetail from "./pages/Admin/ApplicationDetails"
import AdminApplications from "./pages/Admin/Applications"
import AdminLogout from "./pages/Admin/Auth/Logout"
import AdminSignin from "./pages/Admin/Auth/Signin"
import AdminSetting from "./pages/Admin/Setting"
import AdminStudentDetail from "./pages/Admin/StudentDetails"
import AdminStudents from "./pages/Admin/Students"
import Forgot from "./pages/Auth/Forgot"
import ForgotMail from "./pages/Auth/Forgot2"
import NewPass from "./pages/Auth/Forgot3"
import NewPassSuccess from "./pages/Auth/Forgot4"
import Logout from "./pages/Auth/Logout"
import Signin from "./pages/Auth/Signin"
import Signup from "./pages/Auth/Signup"
import SignupMail from "./pages/Auth/Signup2"
import SignupMailCode from "./pages/Auth/Signup3"
import EmailSuccess from "./pages/Auth/Signup4"
import About from "./pages/Home/About"
import FAQ from "./pages/Home/FAQ"
import Home from "./pages/Home/Home"
import Privacy from "./pages/Home/Privacy"
import Terms from "./pages/Home/Terms"
import UserHome from "./pages/User"
import UserSetting from "./pages/User/Setting"
import AdminUsers from "./pages/Admin/Users"
import Contact from "./pages/Home/Contact"
import Conduct from "./pages/Home/Conduct"
import Rules from "./pages/Home/Rules"

function App() {
  const [user, setUser] = useState({})

  const onChange = val => {
    setUser(val)
  }

  return (
    <UserContext.Provider value={{ user, onChange }}>
      <Router>
        <Routes>
          <Route index element={<Home />} />

          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="terms-conditions" element={<Terms />} />
          <Route path="contact" element={<Contact />} />
          <Route path="student-code" element={<Conduct />} />
          <Route path="school-rules" element={<Rules />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="signup">
            <Route index element={<PublicOnlyRoute Page={Signup} />} />
            <Route path="mail-check">
              <Route index element={<PublicOnlyRoute Page={SignupMail} />} />
            </Route>
            <Route path="code">
              <Route
                index
                element={<PublicOnlyRoute Page={SignupMailCode} />}
              />
            </Route>
            <Route path="success">
              <Route index element={<PublicOnlyRoute Page={EmailSuccess} />} />
            </Route>
          </Route>
          <Route path="signin">
            <Route index element={<PublicOnlyRoute Page={Signin} />} />
          </Route>
          <Route path="forgot-password">
            <Route index element={<PublicOnlyRoute Page={Forgot} />} />
            <Route path="mail-check">
              <Route index element={<PublicOnlyRoute Page={ForgotMail} />} />
            </Route>
            <Route path="new-password">
              <Route index element={<PublicOnlyRoute Page={NewPass} />} />
            </Route>
            <Route path="success">
              <Route
                index
                element={<PublicOnlyRoute Page={NewPassSuccess} />}
              />
            </Route>
          </Route>

          <Route path="home" element={<ProtectedRoute Page={UserHome} />} />
          <Route
            path="settings"
            element={<ProtectedRoute Page={UserSetting} />}
          />
          <Route path="logout" element={<ProtectedRoute Page={Logout} />} />

          <Route path="admin">
            <Route
              index
              element={<PublicOnlyAdminRoute Page={AdminSignin} />}
            />
            <Route
              path="signin"
              element={<PublicOnlyAdminRoute Page={AdminSignin} />}
            />
            <Route
              path="home"
              element={<ProtectedAdminRoute Page={AdminHome} />}
            />
            <Route
              path="users"
              element={<ProtectedAdminRoute Page={AdminUsers} />}
            />
            <Route path="students">
              <Route
                index
                element={<ProtectedAdminRoute Page={AdminStudents} />}
              />
              <Route
                path=":userId"
                element={<ProtectedAdminRoute Page={AdminStudentDetail} />}
              />
            </Route>
            <Route path="applications">
              <Route
                index
                element={<ProtectedAdminRoute Page={AdminApplications} />}
              />
              <Route
                path=":userId"
                element={<ProtectedAdminRoute Page={AdminApplicationDetail} />}
              />
            </Route>
            <Route
              path="settings"
              element={<ProtectedAdminRoute Page={AdminSetting} />}
            />
            <Route
              path="logout"
              element={<ProtectedAdminRoute Page={AdminLogout} />}
            />
          </Route>

          <Route path="*" element={<NoPage />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  )
}

export default App
