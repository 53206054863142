import { toast } from "react-toastify"

const Success = (message = "Profile updated!", title = "Success") => {
  toast(message)
}

const Error = (
  message = "Something happened with your request",
  title = "Error"
) => {
  toast(message)
}

// as of v3.3.0 react-notifications-component no longer
// uses default export
export default {
  Success,
  Error
}
