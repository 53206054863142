import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { setJWTToken, setUserId } from "../../api/auth"
import { basic_api } from "../../api/axios_helper"
import Notification from "../../components/Notification"
import successIcon from "../../assets/success.png"

import Container from "react-bootstrap/Container"
import CustomButton from "../../components/button/CustomButton"
import Form from "react-bootstrap/Form"

function NewPassSuccess() {
  let navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    function deleteAllCookies() {
      var cookies = document.cookie.split(";")

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i]
        var eqPos = cookie.indexOf("=")
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
      }
    }

    deleteAllCookies()
  }, [])

  const signin = () => {
    console.log("login")
    if (isLoading) return
    const formData = new FormData()
    formData.append("username", email)
    formData.append("password", password)
    setIsloading(true)
    basic_api
      .post("/api/v1/login/", formData)
      .then(({ data }) => {
        setIsloading(false)
        Notification.Success("Signin completed!")
        setUserId(data?.user.id)
        setJWTToken(data?.token)
        navigate(`/company`)
      })
      .catch(error => {
        console.log(error?.response?.data)
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  return (
    <ContentContainer>
      <CenterContentContainer>
        <div className="d-flex justify-content-center">
          <img src={successIcon} width="60px" height="60px" />
        </div>
        <Title className="d-flex justify-content-center">Password reset</Title>
        <Text className="text-center">
          Your password has been successfully reset. Click below to log in
          magically.
        </Text>
        <Form>
          <ButtonContainer>
            <CustomButton
              title={"Continue to login"}
              onClick={() => navigate("/signin")}
            />
          </ButtonContainer>
        </Form>
      </CenterContentContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled(Container)`
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 100px;
  justify-content: center;
`
const CenterContentContainer = styled.div`
  width: 360px;
`
const Title = styled.h1`
  margin-top: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: val(--text-title-color);
`
const Text = styled.p`
  margin-top: 12px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: val(--text-color);
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0;
`

export default NewPassSuccess
