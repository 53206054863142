import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import PublicHeader from "../../components/header/publicHeader"
import Footer from "./Footer"

function FAQ() {
  let navigate = useNavigate()
  return (
    <MainContainer>
      <PublicHeader />
      <ContentContainer className="d-flex flex-column align-items-center">
        <HeaderHolder>
          <Title>FAQ</Title>
          <Text>{`Answers to Common Questions`}</Text>
        </HeaderHolder>
        <InternalHeader>Will I have an instructor at all times?</InternalHeader>
        <InternalText>
          Behind-the-wheel training involves you, the truck, and your
          instructor.
        </InternalText>
        <InternalHeader>Why Choose Ace Trucking Academy?</InternalHeader>
        <InternalText>
          We offer Houston's most transparent, friendly, and comprehensive
          training. You'll be test-ready in just two weeks.
        </InternalText>
        <InternalHeader>
          Why is Ace Trucking Academy so highly rated?
        </InternalHeader>
        <InternalText>
          Our outstanding curriculum ensures you feel at home while we genuinely
          care about your CDL success and job placement.
        </InternalText>
        <InternalHeader>
          Why are there cheaper schools in Houston?
        </InternalHeader>
        <InternalText>
          Some schools may offer lower prices, but it's essential to consider
          the value you're getting. At Ace Trucking Academy, we provide
          comprehensive training that ensures your success without being the
          most expensive option either. Our impressive 98% success rate, with
          most students passing their road tests on the first attempt, is a
          testament to the quality of our program. We call it "ONE AND DONE!"
          when this happens.
        </InternalText>
        <InternalHeader>Do you offer payment plans?</InternalHeader>
        <InternalText>
          Yes, we offer weekly payment plans with no interest, provided payments
          are made on time.
        </InternalText>
        <InternalHeader>
          I have my Permit, can you just show me to drive?
        </InternalHeader>
        <InternalText>
          While we'll teach you to drive, CDL testing also includes backing the
          truck and trailer through three scenarios, proper inspection of over
          100 truck and trailer parts, all covered in our training.
        </InternalText>
        <InternalHeader>
          English is my second language, do instructors speak different
          languages?
        </InternalHeader>
        <InternalText>
          Certainly, our instructors speak a variety of languages, including
          Spanish, Farsi, Urdu, Arabic, and more.
        </InternalText>
        <InternalHeader>What CDL types do you offer?</InternalHeader>
        <InternalText>
          We provide Class B and Class A CDLs in both automatic and manual
          transmissions. Additionally, we offer various endorsements.
        </InternalText>
        <InternalHeader>What types of trucks do you have?</InternalHeader>
        <InternalText>
          Our fleet includes industry-standard 10-speed manual and 12-speed
          automatic transmissions. Learning the 10-speed is ideal for a CDL
          without automatic restrictions, and it forms a solid foundation for
          mastering 13 and 18 speeds.
        </InternalText>
        <InternalHeader>
          I'm over 60 years old, is there a limit to getting a CDL?
        </InternalHeader>
        <InternalText>
          As long as you can pass a D.O.T. physical exam, you can obtain your
          CDL.
        </InternalText>
        <InternalHeader>
          Can I just rent the truck from Ace Trucking Academy for my test?
        </InternalHeader>
        <InternalText>
          Yes, you can rent our truck for testing as long as you meet the FMCSA
          training requirements. However, we recommend signing up for our full
          training for the best preparation.
        </InternalText>
        <InternalText>
          For further inquiries, please reach us at{" "}
          <a href="mailto:info@acetruckingacademy.com">
            info@acetruckingacademy.com
          </a>{" "}
          or refer to our "Contact Us" page to select your preferred method of
          communication.
        </InternalText>
      </ContentContainer>

      <Footer />
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`
const ContentContainer = styled(Container)`
  margin-top: 98px;
`
const HeaderHolder = styled.div`
  padding: 98px 0;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--text-title-color);
`
const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--text-title-color);
  margin-top: 24px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-color);
  margin-top: 10px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--text-title-color);
`

const FooterContainer = styled.footer`
  width: 100%;
  padding-bottom: 36px;
`
const FooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
`

export default FAQ
