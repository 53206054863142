import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

function Footer() {
  let navigate = useNavigate()
  return (
    <FooterContainer className="d-flex align-items-center justify-content-center">
      <FooterText onClick={() => navigate("/privacy-policy")}>
        Privacy Policy
      </FooterText>
      <FooterText onClick={() => navigate("/terms-conditions")}>
        Terms & Conditions
      </FooterText>
      <FooterText onClick={() => navigate("/school-rules")}>
        School Rules
      </FooterText>
      <FooterText onClick={() => navigate("/student-code")}>
        Student code of Conduct
      </FooterText>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  width: 100%;
  padding-bottom: 36px;
`
const FooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
`

export default Footer
