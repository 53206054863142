import React from "react"
import styled from "styled-components"

import Button from "react-bootstrap/Button"

function CustomButton({style, title, light, type, onClick = () => {} }) {
  return (
    <ButtonItem
      onClick={onClick}
      isLight={light}
      className={light && "btn-light"}
      style={style}
      type={type || "button"}
    >
      {title}
    </ButtonItem>
  )
}

const ButtonItem = styled(Button)`
  background: ${props =>
    !props.isLight ? "var(--primary-button-color)" : "transparent"};
  border: 1px solid #183464;
  padding: 10px 28px;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
`

export default CustomButton
