export const adminHasValidToken = () => {
  const token = getAdminJWTToken()
  return !!token
}

export const setAdminJWTToken = token => {
  window.localStorage.setItem("admin-ace-token", token)
}

export const setAdminUserId = id => {
  window.localStorage.setItem("admin-userId", id)
}

export const getAdminJWTToken = () => {
  return window.localStorage.getItem("admin-ace-token")
}

export const getAdminUserId = () => {
  return window.localStorage.getItem("admin-userId")
}

export const removeJWTToken = () => {
  window.localStorage.removeItem("admin-ace-token")
}

export const setAdminRefreshToken = token => {
  window.localStorage.setItem("admin-refreshToken", token)
}

export const getAdminRefreshToken = () => {
  return window.localStorage.getItem("admin-refreshToken")
}

export const removeRefreshToken = () => {
  window.localStorage.removeItem("admin-refreshToken")
}
