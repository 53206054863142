import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import logoIcon from "../../assets/logo.png"

function PublicHeader() {
  let navigate = useNavigate()
  return (
    <Navbar className="bg-light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand onClick={() => navigate("/")}>
          <HeaderImg src={logoIcon}></HeaderImg>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <HeaderItemListItem onClick={() => navigate("/about")}>
            About Us
          </HeaderItemListItem>
          <HeaderItemListItem onClick={() => navigate("/faq")}>
            FAQ
          </HeaderItemListItem>
          <HeaderItemListItem href="contact">Contact Us</HeaderItemListItem>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
const HeaderImg = styled.img`
  height: 60px;
  width: 60px;
`
const HeaderItemListItem = styled(Nav.Link)`
  margin-left: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
`

export default PublicHeader
