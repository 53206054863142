import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import PublicHeader from "../../components/header/publicHeader"
import Footer from "./Footer"

function Conduct() {
  let navigate = useNavigate()
  return (
    <MainContainer>
      <PublicHeader />
      <ContentContainer className="d-flex flex-column align-items-center">
        <HeaderHolder>
          <Title>
            Ace Trucking Academy Student Code of Conduct and Policies
          </Title>
          <Text>
            {`Trainees must adhere to high standards of Ace Trucking Academy’s code of conduct. Those trainees whose conduct reflects discredit upon him/herself or Ace Trucking Academy may be subject to probation and/or termination. The trainee must adhere to conduct that will not interfere with the learning progress of any other trainees, the classroom presentation by the instructor, or the progress of the class.`}
          </Text>
          <Text>
            {`The administration of Ace Trucking Academy reserves the right, in the exercise of its judgment, to place on probation and/or terminate a trainee on any of the following grounds:`}
          </Text>
        </HeaderHolder>
        <InternalText>
          A. Non-conformity with rules and regulations of Ace Trucking Academy.
        </InternalText>
        <InternalText>
          B. Conduct that reflects unfavorably upon Ace Trucking Academy, its
          staff, faculty and/or its trainees.
        </InternalText>
        <InternalText>C. Unsatisfactory academic progress.</InternalText>
        <InternalText>D. Excessive absences or tardiness.</InternalText>
        <InternalText>
          E. Failure to pay fees when due and/or to make available required
          documents.
        </InternalText>
        <InternalText>F. Cheating.</InternalText>
        <InternalText>
          G. Falsifying Ace Trucking Academy records are strictly prohibited.
        </InternalText>
        <InternalText>
          H. Breach of Ace Trucking Academy Enrollment Agreement.
        </InternalText>
        <InternalText>
          I. Demonstrating poor judgment or inability to function properly
          and/or professionally.
        </InternalText>
        <InternalText>
          J. Entering Ace Trucking Academy premises while under the influence of
          alcohol, drugs, or narcotics of any kind.
        </InternalText>
        <InternalText>
          K. Carrying a concealed or potentially dangerous weapon.
        </InternalText>
        <InternalText>
          L. Using, selling, buying, distributing, or offering for sale any
          illegal substance.
        </InternalText>
        <InternalText>
          M. Aiding, abetting, or inciting others to commit any act that would
          detract from the continuity of the normal operation.
        </InternalText>
        <InternalText>N. Theft.</InternalText>
        <InternalText>
          A trainee terminated for non-adherence to the listed codes of conduct
          may be readmitted into the program only at the discretion of Ace
          Trucking Academy. A trainee placed on probation may be removed from
          probation status if he/she demonstrates continued adherence to Ace
          Trucking Academy policies and codes of conduct.
        </InternalText>
      </ContentContainer>

      <Footer />
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`
const ContentContainer = styled(Container)`
  margin-top: 98px;
`
const HeaderHolder = styled.div`
  padding: 98px 0;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;

  color: var(--text-title-color);
`
const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--text-title-color);
  margin-top: 24px;

  //

  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--text-color);
  margin-top: 10px;

  //
  @media (min-width: 768px) {
    width: 768px;
  }
`
const InternalHeader = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--text-title-color);
`

export default Conduct
