import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import search from "../../assets/search.svg"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import CustomButton from "../../components/button/CustomButton"

import InputGroup from "react-bootstrap/InputGroup"

import "primereact/resources/themes/lara-light-indigo/theme.css"
import { main_admin_api } from "../../api/axios_admin_helper"
import Notification from "../../components/Notification"
import AuthAdminHeader from "../../components/header/authAdminHeader"

function AdminApplications() {
  let navigate = useNavigate()
  const [isloading, setIsloading] = useState(false)
  const [pageData, setPageData] = useState({})
  const [searchUsername, setSearchUsername] = useState("")

  const [applicationData, setApplicationData] = useState([])

  const _getApplications = url => {
    setIsloading(true)

    //Server should do this
    main_admin_api
      .get(
        url?.replace("http", "https") ??
          `/admin/api/v1/student_applications/?limit=10`
      )
      .then(({ data }) => {
        setIsloading(false)
        setApplicationData(data?.results)
        setPageData(data)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  useEffect(() => {
    _getApplications()
  }, [])

  const titleTemplate = data => {
    return (
      <TableTitleContainer>
        <DocumentIconHolder src={`${data?.profile?.photo}`} />
        <DocumentTextHolder>
          <DocumentText>{`${data?.first_name} ${data?.last_name}`}</DocumentText>
          <DocumentTextSub>{`${data?.email}`}</DocumentTextSub>
        </DocumentTextHolder>
      </TableTitleContainer>
    )
  }
  const classTemplate = data => {
    return (
      <TableClassContainer>
        <DocumentText>{data?.enrolled_class?.class_id?.title}</DocumentText>
        <DocumentTextSub>
          {data?.enrolled_class?.transmission_type?.name}
        </DocumentTextSub>
      </TableClassContainer>
    )
  }
  const statusTemplate = data => {
    return (
      <TableStatusContainer>
        {data?.documents?.map(doc => (
          <DocStatusTag>
            <DocStatusText>
              {doc?.document_type?.title?.slice(0, 5)}
            </DocStatusText>
          </DocStatusTag>
        ))}
      </TableStatusContainer>
    )
  }
  const actionTemplate = data => {
    return (
      <TableActionContainer className="d-flex justify-content-end">
        <TableActionButton>
          {data?.status == "Approved" ? (
            <CustomButton
              style={{ backgroundColor: "green" }}
              onClick={() => navigate(`${data?.id}`)}
              title={"Open Application"}
            />
          ) : (
            <CustomButton
              onClick={() => navigate(`${data?.id}`)}
              title={"Open Application"}
            />
          )}
        </TableActionButton>
      </TableActionContainer>
    )
  }

  const tableHeader = () => {
    return (
      <TableHeaderContainer className="d-flex justify-content-between align-items-center">
        <InputGroupContainer>
          <Form
            onSubmit={e => {
              e.preventDefault()
              _getApplications(
                `/admin/api/v1/student_applications/?search=${searchUsername}&limit=10`
              )
            }}
          >
            <InputGroup className="d-flex align-items-center">
              <InputGroupIcon src={search} id="basic-addon1" />
              <FormControl
                placeholder="Search"
                aria-label="search"
                aria-describedby="search-id"
                value={searchUsername}
                onChange={e => {
                  setSearchUsername(e.target.value)
                }}
              />
            </InputGroup>
          </Form>
        </InputGroupContainer>
      </TableHeaderContainer>
    )
  }
  const tableFooter = () => {
    return (
      <TableFooterContainer className="d-flex justify-content-between align-items-center">
        <TableFooterText>
          Page{" "}
          {parseInt(pageData?.previous?.split("=")?.slice(-1) ?? 0) +
            applicationData?.length}{" "}
          of {pageData?.count}
        </TableFooterText>
        <TableFooterButtons className="d-flex">
          <TableFooterButton>
            <CustomButton
              light={!Boolean(pageData?.previous)}
              onClick={() => {
                if (Boolean(pageData?.previous)) {
                  _getApplications(pageData?.previous)
                }
              }}
              title={"Previous"}
            />
          </TableFooterButton>
          <TableFooterButton>
            <CustomButton
              light={!Boolean(pageData?.next)}
              onClick={() => {
                if (Boolean(pageData?.next)) {
                  _getApplications(pageData?.next)
                }
              }}
              title={"Next"}
            />
          </TableFooterButton>
        </TableFooterButtons>
      </TableFooterContainer>
    )
  }
  return (
    <ContentContainer>
      <AuthAdminHeader />
      <MainContent>
        <ControledContainer>
          <Title>Applications</Title>
          <TableContainer>
            <DataTable
              style={{ border: "none", maxWidth: "100%", overflow: "scroll" }}
              tableStyle={{
                border: "none",
                background: "green",
                maxWidth: "100%",
                overflow: "scroll",
                border: "1px solid #EAECF0",
                borderRadius: 120,
                width: "100%"
              }}
              footer={tableFooter}
              value={applicationData}
              header={tableHeader}
              emptyMessage="No pending request found."
              responsiveLayout="scroll"
              editMode="row"
              sortOrder={1}
            >
              <Column header="Applicants' Name" body={titleTemplate} />
              <Column header="Class Enrolled" body={classTemplate} />
              <Column header="Documents Uploaded" body={statusTemplate} />
              <Column header="Actions" body={actionTemplate} />
            </DataTable>
          </TableContainer>
        </ControledContainer>
      </MainContent>
    </ContentContainer>
  )
}
const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #f9fafb;
`
const ControledContainer = styled(Container)`
  @media (min-width: 992px) {
    margin-left: 240px;
  }
`
const MainContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  @media (min-width: 992px) {
    margin-top: 32px;
  }
  margin-top: 15vh;
`
const ClassCardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  height: 176px;
  padding: 24px;

  @media (max-width: 575.98px) {
    height: 270px;
  }
`
const ClassTag = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 8px;
`
const ClassTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
`
const ClassTitleContainer = styled.div`
  margin-bottom: 16px;

  @media (max-width: 575.98px) {
    flex-direction: column;
  }
`
const ButtonContainer = styled.div`
  min-width: 130px;

  @media (max-width: 575.98px) {
    width: 100%;
  }
`
const CalendarContainer = styled.div`
  width: 250px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 11.67px 18.67px;
`
const CalendarIcon = styled.img`
  width: 15px;
  height: 100%;
  margin-right: 10px;
`
const CalendarDate = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`

const StatusTag = styled.div`
  background: #ecfdf3;
  mix-blend-mode: multiply;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px 2px 8px;
  gap: 4px;
  height: 24px;
  margin-left: 16px;
`
const StatusImg = styled.img`
  width: 12px;
  height: 12px;
`
const DocStatusTag = styled.div`
  background: #ecfdf3;
  mix-blend-mode: multiply;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px 2px 8px;
  gap: 4px;
  height: 24px;
`
const DocStatusText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #027a48;
`
const StatusText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #027a48;
`
const TagContainer = styled.div``
const TableContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-bottom: 32px;
`
const TableHeaderContainer = styled.div`
  padding: 12px 16px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 24px;
`
const TableHeaderButtons = styled.div`
  width: 182px;
  height: 40px;
  border: 1px solid #d0d5dd;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;
`
const TableHeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 87px;
  height: 100%;
  background: #f9fafb;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
  border-right: 1px solid #d0d5dd;
`
const TableHeaderButtonRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 87px;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
`
const InputGroupIcon = styled.img`
  width: 20px;
  height: 20px;
`
const FormControl = styled(Form.Control)`
  border-width: 0;
`
const InputGroupContainer = styled.div`
  width: 50%;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0 16px;
  align-items: center;
`
const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 24px;
  width: 390px;
  box-sizing: border-box;
`
const TableClassContainer = styled.div`
  align-items: center;
  width: 190px;
  box-sizing: border-box;
`
const TableStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  box-sizing: border-box;
`
const TableActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  width: 230px;
  height: 40px;
  box-sizing: border-box;
`
const DocumentIconHolder = styled.img`
  width: 40px;
  height: 40px;
  background: rgba(243, 245, 247, 0.8);
  border-radius: 20px;
`
const DocumentTextHolder = styled.div`
  margin-left: 12px;
`
const DocumentText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  margin: 0;
`
const DocumentTextSub = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`
const TableActionButton = styled.div`
  width: 180px;
`
const TableActionText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  margin-left: 12px;
  width: 92px;
`

const TableFooterContainer = styled.div`
  border-radius: 0px 10px 10px 0px;
`
const TableFooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`
const TableFooterButtons = styled.div``
const TableFooterButton = styled.div`
  width: 120px;
  margin-left: 10px;
`

const ModalTitle = styled(Modal.Title)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`
const ModalSubTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

const ModalLeftIconHolder = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  padding: 15px 15px;
  margin-right: 16px;
`
const ModalLeftIcon = styled.img`
  height: 18px;
  width: 20px;
`
const RowText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  or: #344054;
`

const ModalUploadIcon = styled.img`
  height: 64px;
  width: 64px;
  margin-right: 20px;
`

const DropZoneConatainer = styled.section`
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 74px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`

const DropZoneTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 20px;
  color: #6941c6;
  text-align: center;
`
const DropZoneText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 20px;
  color: #475467;
  text-align: center;
  margin: 4px;
`

export default AdminApplications
