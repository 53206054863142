import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import PublicHeader from "../../components/header/publicHeader"
import CustomButton from "../../components/button/CustomButton"
import Footer from "./Footer"

function Home() {
  let navigate = useNavigate()
  return (
    <MainContainer>
      <PublicHeader />
      <ContentContainer className="d-flex flex-column align-items-center justify-content-center">
        <Title>Welcome to Ace Trucking Academy</Title>
        <Text>
          {`At Ace Trucking Academy, we're dedicated to your success. Our comprehensive CDL training programs are designed to fast-track your journey to becoming a professional CDL driver. With over 5 years of experience and more than 2000 graduates, we are Houston's top-rated CDL school. Our core values include in-depth training, unwavering support, and creating opportunities for our students.`}
        </Text>
        <Row className="mt-4">
          <Col className="col-sm-6 col-12">
            <ButtonContainer>
              <CustomButton
                title={"Create an account"}
                onClick={() => navigate("signup")}
              />
            </ButtonContainer>
          </Col>
          <Col className="col-sm-6 col-12 mt-sm-0 mt-3">
            <ButtonContainer>
              <CustomButton
                onClick={() => navigate("signin")}
                light
                title={"Login"}
              />
            </ButtonContainer>
          </Col>
        </Row>
      </ContentContainer>

      <Footer />
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`
const ContentContainer = styled(Container)`
  height: calc(100% - 60px);
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
  @media (max-width: 767.98px) {
    font-size: 20px;
  }
`
const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #475467;
  margin-top: 24px;
`

const ButtonContainer = styled.div`
  width: 192px;
  @media (max-width: 767.98px) {
    width: 100%;
  }
  padding: 0;
`

const ButtonItem = styled(Button)`
  background: #183464;
  border: 1px solid #183464;
  padding: 16px 28px;
`

const FooterContainer = styled.footer`
  width: 100%;
`
const FooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
`

export default Home
