import React from "react"
import styled from "styled-components"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/esm/Row"
import AuthAdminHeader from "../../components/header/authAdminHeader"

function AdminSetting() {
  return (
    <ContentContainer>
      <AuthAdminHeader />
      <MainContent>
        <ControledContainer>
          <Title>Settings</Title>
        </ControledContainer>
      </MainContent>
    </ContentContainer>
  )
}
const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const ControledContainer = styled(Container)`
  @media (min-width: 992px) {
    margin-left: 240px;
  }
`
const MainContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  @media (min-width: 992px) {
    margin-top: 32px;
  }
  margin-top: 15vh;
`

const TabNavContainer = styled.nav`
  height: 32px;
  border-bottom: 1px solid #eaecf0;
  margin-top: 24px;
`
const TabNavItem = styled.li`
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 4px 11px;
  gap: 8px;
  height: 32px;
  color: #667085;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  font-weight: ${props => (props.active ? "600" : "400")};
  border-bottom: 2px solid
    ${props => (props.active ? "#183464" : "transparent")};

  &:hover {
    font-weight: 600;
    border-bottom: 2px solid #183464;
  }

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`

const SubTitle = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-top: 30px;
  margin-bottom: 0;
`

const SubDescription = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 20px;
`

const FormLabel = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  width: ${props => !props.noWidth && "280px"};
  margin: 0;
  margin-right: ${props => !props.noWidth && "32px"};
`
const FormLabelDescription = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`
const LabelGroup = styled.div`
  width: 280px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
`

const FormControl = styled(Form.Control)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 512px;
`

const FormSelect = styled(Form.Select)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 512px;
`

const FormGroup = styled(Form.Group)`
  display: flex;
  flex-direction: row;
  padding: 24px 0;
  border-bottom: 1px solid #eaecf0;
`

const ActionButtons = styled.div`
  padding: 48px 10px;
  @media (max-width: 575.98px) {
    flex-direction: column;
  }
`
const ActionButton = styled.div`
  min-width: 100px;
  margin-left: 10px;
  @media (max-width: 575.98px) {
    margin-left: 0;
    min-width: 100%;
    margin-top: 10px;
  }
`
const DropZoneConatainer = styled.section`
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 126px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`

const DropZoneImg = styled.img`
  width: 40px;
  height: 40px;
`

const DropZoneTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  text-align: center;
`
const DropZoneText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  text-align: center;
  margin: 4px;
`

const ProfileDropZone = styled(Row)`
  width: 100%;
  max-width: 512px;
  margin-left: 10px;
`

const HeaderImg = styled.img`
  height: 64px;
  width: 64px;
`

const UploadingFileContainer = styled(Row)`
  padding: 16px;
  margin-left: 2px;
  margin-top: 16px;
  width: 100%;
  height: 75px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 12px;
`

const UploadImg = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 100%;
`

const DeleteImg = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
`
const UploadName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin: 0;
  //Stop overflow
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const UploadSize = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

export default AdminSetting
