export const userHasValidToken = () => {
  const token = getJWTToken()
  return !!token
}

export const setJWTToken = token => {
  window.localStorage.setItem("ace-token", token)
}

export const setUserId = id => {
  window.localStorage.setItem("userId", id)
}

export const getJWTToken = () => {
  return window.localStorage.getItem("ace-token")
}

export const getUserId = () => {
  return window.localStorage.getItem("userId")
}

export const removeJWTToken = () => {
  window.localStorage.removeItem("ace-token")
}

export const setRefreshToken = token => {
  window.localStorage.setItem("refreshToken", token)
}

export const getRefreshToken = () => {
  return window.localStorage.getItem("refreshToken")
}

export const removeRefreshToken = () => {
  window.localStorage.removeItem("refreshToken")
}
