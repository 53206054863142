import React, { useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"

import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { main_admin_api } from "../../api/axios_admin_helper"
import ChartIcon from "../../assets/ChartIcon"
import FolderIcon from "../../assets/FolderIcon"
import LogoutIcon from "../../assets/LogoutIcon"
import PeopleIcon from "../../assets/PeopleIcon"
import SettingsIcon from "../../assets/SettingsIcon.js"
import Notification from "../Notification"
import logoIcon from "../../assets/logo.png"
import UserContext from "../../context/UserContext"
import { getAdminUserId } from "../../api/adminauth"

function AuthAdminHeader() {
  let navigate = useNavigate()

  const { pathname } = useLocation()

  const { user, onChange } = useContext(UserContext)

  const _logout = () => {
    main_admin_api
      .get("/api/v1/users/logout/")
      .then(({ data }) => {
        Notification.Success("Logged out successfully")
        navigate("/admin/logout")
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
      })
  }

  const _getUserProfile = () => {
    main_admin_api
      .get(`/api/v1/users/${getAdminUserId()}/`)
      .then(({ data }) => {
        onChange(data)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
      })
  }

  useEffect(() => {
    if (!user?.id) {
      _getUserProfile()
    }
  }, [])

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <NavbarContainer expand="lg" fixed="top">
          <Navbar.Brand
            className="d-flex align-items-center"
            onClick={() => navigate("/")}
          >
            <HeaderImg src={logoIcon} />
            <LogoTitle>Ace Academy</LogoTitle>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <NavbarCollapse className="justify-content-end">
            <HeaderNavLinkContainer
              className="d-flex align-items-center"
              active={pathname === "/admin/home"}
            >
              <ItemImg>
                <ChartIcon
                  stroke={pathname === "/admin/home" ? "#183464" : "#fff"}
                />
              </ItemImg>
              <HeaderItemListItem onClick={() => navigate("/admin/home")}>
                Home
              </HeaderItemListItem>
            </HeaderNavLinkContainer>
            <HeaderNavLinkContainer
              className="d-flex align-items-center"
              active={pathname.includes("/admin/applications")}
            >
              <ItemImg>
                <FolderIcon
                  stroke={
                    pathname.includes("/admin/applications")
                      ? "#183464"
                      : "#fff"
                  }
                />
              </ItemImg>
              <HeaderItemListItem
                onClick={() => navigate("/admin/applications")}
              >
                Applications
              </HeaderItemListItem>
            </HeaderNavLinkContainer>
            <HeaderNavLinkContainer
              className="d-flex align-items-center"
              active={pathname.includes("/admin/students")}
            >
              <ItemImg>
                <PeopleIcon
                  stroke={
                    pathname.includes("/admin/students") ? "#183464" : "#fff"
                  }
                />
              </ItemImg>
              <HeaderItemListItem onClick={() => navigate("/admin/students")}>
                Students
              </HeaderItemListItem>
            </HeaderNavLinkContainer>
            <HeaderNavLinkContainer
              className="d-flex align-items-center"
              active={pathname.includes("/admin/users")}
            >
              <ItemImg>
                <PeopleIcon
                  stroke={
                    pathname.includes("/admin/users") ? "#183464" : "#fff"
                  }
                />
              </ItemImg>
              <HeaderItemListItem onClick={() => navigate("/admin/users")}>
                Users
              </HeaderItemListItem>
            </HeaderNavLinkContainer>
            <HeaderNavLinkContainer
              className="d-flex align-items-center"
              active={pathname === "/admin/settings"}
            >
              <ItemImg>
                <SettingsIcon
                  stroke={pathname === "/admin/settings" ? "#183464" : "#fff"}
                />
              </ItemImg>
              <HeaderItemListItem onClick={() => navigate("/admin/settings")}>
                Settings
              </HeaderItemListItem>
            </HeaderNavLinkContainer>
          </NavbarCollapse>
          <NavbarCollapse className="justify-content-end">
            <HeaderNavLinkContainer className="d-flex align-items-center">
              <ItemImg>
                <LogoutIcon stroke={"#fff"} />
              </ItemImg>
              <HeaderItemListItem onClick={_logout}>Logout</HeaderItemListItem>
            </HeaderNavLinkContainer>
            <NavFooterContent>
              <HeaderImg src={user?.profile?.photo} />
              <TextContainer>
                <NameText>{user?.name}</NameText>
                <EmailText>{user?.email}</EmailText>
              </TextContainer>
            </NavFooterContent>
          </NavbarCollapse>
        </NavbarContainer>
      )}
    </UserContext.Consumer>
  )
}
const HeaderImg = styled.img`
  height: 40px;
  width: 40px;
`
const ItemImg = styled.div`
  margin-top: -5px;
  margin-right: 14px;
`
const HeaderNavLinkContainer = styled(Nav.Link)`
  padding: 10px 14px;
  width: 100%;
  background-color: ${props => (props.active ? "#fff" : "transparent")};
  color: ${props => (props.active ? "#183464" : "#fff")};
  font-weight: ${props => (props.active ? "700" : "400")};
  margin: 5px 0;
  border-radius: 6px;
`
const HeaderItemListItem = styled(Nav.Link)`
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  width: 100%;
`
const NavbarContainer = styled(Navbar)`
  @media (min-width: 992px) {
    height: 100vh;
    width: 239px;
    flex-direction: column;
  }
  padding: 28px 17px;
  background-color: #183464;
`
const NavbarCollapse = styled(Navbar.Collapse)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  // flex-grow: 0;
`
const LogoTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-left: 16px;
`
const NavFooterContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 32px 0px 8px;
  gap: 47px;
  width: 100%;
  height: 64px;
  border-top: 1px solid #ffffff;
`
const TextContainer = styled.div`
  margin-left: -30px;
`
const NameText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
`
const EmailText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #f4ebff;
  margin: 0;
`

export default AuthAdminHeader
