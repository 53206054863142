import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import calendar from "../../assets/calendar.svg"
import arrow from "../../assets/arrow-up.svg"
import alert from "../../assets/alert-circle.svg"
import search from "../../assets/search.svg"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import houseIcon from "../../assets/house.png"
import addImageIcon from "../../assets/add-image.png"
import Dropzone from "react-dropzone"
import moment from "moment"

import Container from "react-bootstrap/Container"
import CustomButton from "../../components/button/CustomButton"
import Form from "react-bootstrap/Form"
import AuthHeader from "../../components/header/authHeader"

import InputGroup from "react-bootstrap/InputGroup"

import "primereact/resources/themes/lara-light-indigo/theme.css"
import UserContext from "../../context/UserContext"
import Notification from "../../components/Notification"
import { main_api } from "../../api/axios_helper"
import ResponseModal from "../../components/modal/ResponseModal"
import { getUserId } from "../../api/auth"

var varUpdatedClassData = []
function UserHome() {
  let navigate = useNavigate()
  const [classStatus, setClassStatus] = useState(0)
  const [enrollmentStep, setEnrollmentStep] = useState(1)
  const [enrollmentModal, setEnrollmentModal] = useState(false)
  const [selectedEnrollmentModal, setSelectedEnrollment] = useState(false)
  const [showResponseModal, setShowResponseModal] = useState(false)

  const { user, onChange } = useContext(UserContext)

  const [license, setLicense] = useState(undefined)
  const [licenseType, setLicenseType] = useState("A")
  const [transmission, setTransmission] = useState(undefined)

  const [isLoading, setIsloading] = useState(false)

  const [classData, setClassData] = useState([])
  const [licenseData, setLicenseData] = useState([])
  const [transmissionData, setTransmissionData] = useState([])
  const [updatedClassData, setUpdatedClassData] = useState([])

  //Check if user profile is complete
  const [isProfileInformationComplete, setIsProfileInformationComplete] =
    useState(false)
  const [isProfileInformationPending, setIsProfileInformationPending] =
    useState(false)

  const _getUserProfile = () => {
    if (isLoading) return
    setIsloading(true)
    main_api
      .get(`/api/v1/users/${getUserId()}/`)
      .then(({ data }) => {
        setIsloading(false)
        if (data?.profile?.state && data?.is_student_admitted)
          setIsProfileInformationComplete(true)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getDocuments = () => {
    if (isLoading) return

    setIsloading(true)
    main_api
      .get(`/api/v1/document_types/`)
      .then(({ data }) => {
        setIsloading(false)
        //TODO: if it ever gets to pagination
        // setDocumentTypes(data?.results)
        data?.results?.filter(tt => {
          if (["Pending"].includes(tt.last_student_upload?.status)) {
            setIsProfileInformationPending(true)
          }
        })
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  useEffect(() => {
    _getUserProfile()
    _getDocuments()
  }, [])

  const _getClasses = () => {
    if (isLoading) return

    setIsloading(true)
    main_api
      .get(`/api/v1/classes/`)
      .then(({ data }) => {
        setIsloading(false)
        setClassData(data?.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _getEnrolData = () => {
    main_api
      .get(`/api/v1/transmission_types/`)
      .then(({ data }) => {
        setIsloading(false)
        setTransmissionData(data?.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
      })
    main_api
      .get(`/api/v1/license_types/`)
      .then(({ data }) => {
        setIsloading(false)
        setLicenseData(data?.results)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
      })
  }

  const _enrolClass = id => {
    if (isLoading) return

    const payload = {
      license_type: licenseType,
      transmission_type: transmission,
      class_id: id
    }

    setIsloading(true)
    main_api
      .post(`/api/v1/class_enrollment/`, payload)
      .then(({ data }) => {
        setShowResponseModal(true)
        setEnrollmentModal(false)
        _getClasses()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  useEffect(() => {
    _getClasses()
    _getEnrolData()
  }, [])

  const titleTemplate = data => {
    return (
      <TableTitleContainer>
        <DocumentIconHolder></DocumentIconHolder>
        <DocumentTextHolder>
          <DocumentText>Catalog</DocumentText>
          <DocumentTextSub>200 KB</DocumentTextSub>
        </DocumentTextHolder>
      </TableTitleContainer>
    )
  }
  const statusTemplate = data => {
    return (
      <TableStatusContainer>
        <DocStatusTag>
          <DocStatusText>Completed</DocStatusText>
        </DocStatusTag>
      </TableStatusContainer>
    )
  }
  const actionTemplate = data => {
    return (
      <TableActionContainer className="d-flex justify-content-end">
        <TableActionButton>
          <CustomButton title={"Open"} />
        </TableActionButton>
        <TableActionText>Mark as done</TableActionText>
      </TableActionContainer>
    )
  }

  const tableHeader = () => {
    return (
      <TableHeaderContainer className="d-flex justify-content-between align-items-center">
        <TableHeaderButtons className="d-flex">
          <TableHeaderButton>Materials</TableHeaderButton>
          <TableHeaderButtonRight>Quizzes</TableHeaderButtonRight>
        </TableHeaderButtons>
        <InputGroupContainer>
          <InputGroup className="d-flex align-items-center">
            <InputGroupIcon src={search} id="basic-addon1" />
            <FormControl
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </InputGroupContainer>
      </TableHeaderContainer>
    )
  }
  const tableFooter = () => {
    return (
      <TableFooterContainer className="d-flex justify-content-between align-items-center">
        <TableFooterText>Page 1 of 10</TableFooterText>
        <TableFooterButtons className="d-flex">
          <TableFooterButton>
            <CustomButton light title={"Previous"} />
          </TableFooterButton>
          <TableFooterButton>
            <CustomButton light title={"Next"} />
          </TableFooterButton>
        </TableFooterButtons>
      </TableFooterContainer>
    )
  }
  return (
    <UserContext.Consumer>
      {({ user }) => (
        <ContentContainer>
          <AuthHeader />
          <MainContent>
            <ControledContainer>
              {!isProfileInformationComplete ? (
                <InfoBoxContainer>
                  <Image src={alert} />
                  <InfoTitle>
                    {isProfileInformationPending
                      ? "Your information has been recieved"
                      : "For your attention"}
                  </InfoTitle>
                  <InfoDescription>
                    {isProfileInformationPending
                      ? "Your documents have been received, we will review documents within the next 24 hours. If any additional documents are needed we will reach out via email"
                      : "Kindly complete your registration and documentation to have access to the classes"}
                  </InfoDescription>

                  <InfoButtonHolder>
                    <CustomButton
                      light={isProfileInformationPending}
                      title={"Complete Onboarding"}
                      onClick={() =>
                        !isProfileInformationPending && navigate("/settings")
                      }
                    />
                  </InfoButtonHolder>
                </InfoBoxContainer>
              ) : (
                <>
                  <Title>Overview</Title>
                  {classStatus == 0 &&
                    classData?.map(classItem => (
                      <ClassCardContainer>
                        <TagContainer className="d-flex">
                          <ClassTag>Class available</ClassTag>
                          {classStatus === 0 ? (
                            <></>
                          ) : classStatus === 1 ? (
                            <StatusTag>
                              <StatusImg src={arrow}></StatusImg>
                              <StatusText>Ongoing</StatusText>
                            </StatusTag>
                          ) : (
                            <StatusTag>
                              <StatusImg src={arrow}></StatusImg>
                              <StatusText>Completed</StatusText>
                            </StatusTag>
                          )}
                        </TagContainer>
                        <ClassTitleContainer className="d-flex align-items-center justify-content-between">
                          <ClassTitle>{classItem?.title}</ClassTitle>
                          <ButtonContainer>
                            <CustomButton
                              title={
                                classItem.is_enrolled ? "View" : "Enroll Now"
                              }
                              onClick={() => {
                                setSelectedEnrollment(classItem)
                                if (classItem.is_enrolled) {
                                  setClassStatus(1)
                                } else {
                                  setEnrollmentModal(true)
                                }
                              }}
                            />
                          </ButtonContainer>
                        </ClassTitleContainer>
                        <CalendarContainer className="d-flex align-items-center">
                          <CalendarIcon src={calendar} />
                          <CalendarDate>
                            {moment(classItem?.start_date)?.format(
                              "MMM d, YYYY"
                            )}{" "}
                            -{" "}
                            {moment(classItem?.end_date)?.format("MMM d, YYYY")}
                          </CalendarDate>
                        </CalendarContainer>
                      </ClassCardContainer>
                    ))}
                  {classStatus !== 0 && (
                    <>
                      <ClassCardContainer>
                        <TagContainer className="d-flex">
                          <ClassTag>Class available</ClassTag>
                          {classStatus === 0 ? (
                            <></>
                          ) : classStatus === 1 ? (
                            <StatusTag>
                              <StatusImg src={arrow}></StatusImg>
                              <StatusText>Ongoing</StatusText>
                            </StatusTag>
                          ) : (
                            <StatusTag>
                              <StatusImg src={arrow}></StatusImg>
                              <StatusText>Completed</StatusText>
                            </StatusTag>
                          )}
                        </TagContainer>
                        <ClassTitleContainer className="d-flex align-items-center justify-content-between">
                          <ClassTitle>
                            {selectedEnrollmentModal?.title}
                          </ClassTitle>
                          <ButtonContainer>
                            <CustomButton
                              light
                              title={"Download my certificate"}
                              onClick={() => {}}
                            />
                          </ButtonContainer>
                        </ClassTitleContainer>
                        <CalendarContainer className="d-flex align-items-center">
                          <CalendarIcon src={calendar} />
                          <CalendarDate>
                            {moment(
                              selectedEnrollmentModal?.start_date
                            )?.format("MMM d, YYYY")}{" "}
                            -{" "}
                            {moment(selectedEnrollmentModal?.end_date)?.format(
                              "MMM d, YYYY"
                            )}
                          </CalendarDate>
                        </CalendarContainer>
                      </ClassCardContainer>
                      <TableContainer>
                        <DataTable
                          style={{
                            border: "none",
                            maxWidth: "100%",
                            overflow: "scroll"
                          }}
                          tableStyle={{
                            border: "none",
                            background: "green",
                            maxWidth: "100%",
                            overflow: "scroll",
                            border: "1px solid #EAECF0",
                            borderRadius: 120,
                            width: "100%"
                          }}
                          footer={tableFooter}
                          value={classData}
                          header={tableHeader}
                          emptyMessage="No pending request found."
                          responsiveLayout="scroll"
                          editMode="row"
                          sortOrder={1}
                        >
                          <Column header="Title" body={titleTemplate} />
                          <Column header="Status" body={statusTemplate} />
                          <Column header="Actions" body={actionTemplate} />
                        </DataTable>
                      </TableContainer>
                    </>
                  )}
                  <Modal
                    show={enrollmentModal}
                    onHide={() => setEnrollmentModal(false)}
                  >
                    <Modal.Header closeButton>
                      <ModalLeftIconHolder>
                        <ModalLeftIcon src={houseIcon} />
                      </ModalLeftIconHolder>
                      <div className="d-flex flex-column justify-content-center">
                        <ModalTitle>
                          {selectedEnrollmentModal?.title}
                        </ModalTitle>
                        <ModalSubTitle>Step 1 out of 1</ModalSubTitle>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      {enrollmentStep === 1 ? (
                        <>
                          {/* <Row>
                        <Col className="col-5">
                          <RowText>Do you have a permit license?*</RowText>
                        </Col>
                        <Col className="col-7">
                          <Form.Select
                            value={license}
                            onChange={e => setLicense(e.target.value)}
                            aria-label="Default select example"
                          >
                            <option>Choose a selection</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                        </Col>
                      </Row> */}
                          {/* {license === "Yes" && ( */}
                          <Row className="mt-2 pt-3 border-top">
                            <Col className="col-5">
                              <RowText>License type*</RowText>
                            </Col>
                            <Col className="col-7">
                              <Form.Select
                                value={licenseType}
                                onChange={e => setLicenseType(e.target.value)}
                                aria-label="Default select example"
                              >
                                <option>Select license type</option>
                                {licenseData?.map(license => (
                                  <option value={license.id}>
                                    {license.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                          </Row>
                          {/* )} */}

                          <Row className="mt-2 pt-3 border-top">
                            <Col className="col-5">
                              <RowText>Type of transamission*</RowText>
                            </Col>
                            <Col className="col-7">
                              <Form.Select
                                value={transmission}
                                onChange={e => setTransmission(e.target.value)}
                                aria-label="Default select example"
                              >
                                <option>Select transmission type</option>
                                {transmissionData?.map(transmission => (
                                  <option value={transmission.id}>
                                    {transmission.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                          </Row>
                          {/* <Row className="mt-2 pt-3 border-top">
                        <Col className="col-5">
                          <RowText>Upload permit license*</RowText>
                        </Col>
                        <Col className="col-7">
                          <Row>
                            <Col className="col-3">
                              <ModalUploadIcon src={addImageIcon} />
                            </Col>
                            <Col className="col-9">
                              <Dropzone
                                onDrop={acceptedFiles =>
                                  console.log(acceptedFiles)
                                }
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <DropZoneConatainer>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <DropZoneText>
                                        <DropZoneTitle>
                                          Click to upload{" "}
                                        </DropZoneTitle>
                                        or drag and drop
                                      </DropZoneText>
                                      <DropZoneText>
                                        SVG, PNG, JPG or GIF (max. 800x400px)
                                      </DropZoneText>
                                    </div>
                                  </DropZoneConatainer>
                                )}
                              </Dropzone>
                            </Col>
                          </Row>
                        </Col>
                      </Row> */}
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col className="col-5">
                              <RowText>
                                What type of license do you want?*
                              </RowText>
                            </Col>
                            <Col className="col-7">
                              <Form.Select aria-label="Default select example">
                                <option>Select license type</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                            </Col>
                          </Row>
                          <Row className="mt-2 pt-3 border-top">
                            <Col className="col-5">
                              <RowText>Type of transamission*</RowText>
                            </Col>
                            <Col className="col-7">
                              <Form.Select aria-label="Default select example">
                                <option>Select transmission type</option>
                                <option value="1">Yes</option>
                              </Form.Select>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <CustomButton
                        title={"Next"}
                        onClick={() => {
                          _enrolClass(selectedEnrollmentModal?.id)
                        }}
                      />
                    </Modal.Footer>
                  </Modal>

                  <ResponseModal
                    isShow={showResponseModal}
                    callback={() => setShowResponseModal(!showResponseModal)}
                    title="Review Initiated"
                    description="Kindly await an email pertaining to your enrollment."
                  />
                </>
              )}
            </ControledContainer>
          </MainContent>
        </ContentContainer>
      )}
    </UserContext.Consumer>
  )
}
const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #f9fafb;
`
const ControledContainer = styled(Container)`
  @media (min-width: 992px) {
    margin-left: 240px;
  }
`
const MainContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  @media (min-width: 992px) {
    margin-top: 32px;
  }
  margin-top: 15vh;
`
const ClassCardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  height: 176px;
  padding: 24px;
  margin-bottom: 5px;

  @media (max-width: 575.98px) {
    height: 270px;
  }
`
const ClassTag = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 8px;
`
const ClassTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
`
const ClassTitleContainer = styled.div`
  margin-bottom: 16px;

  @media (max-width: 575.98px) {
    flex-direction: column;
  }
`
const ButtonContainer = styled.div`
  min-width: 130px;

  @media (max-width: 575.98px) {
    width: 100%;
  }
`
const CalendarContainer = styled.div`
  width: 250px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 11.67px 18.67px;
`
const CalendarIcon = styled.img`
  width: 15px;
  height: 100%;
  margin-right: 10px;
`
const CalendarDate = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`

const StatusTag = styled.div`
  background: #ecfdf3;
  mix-blend-mode: multiply;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px 2px 8px;
  gap: 4px;
  height: 24px;
  margin-left: 16px;
`
const StatusImg = styled.img`
  width: 12px;
  height: 12px;
`
const DocStatusTag = styled.div`
  background: #ecfdf3;
  mix-blend-mode: multiply;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px 2px 8px;
  gap: 4px;
  height: 24px;
`
const DocStatusText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #027a48;
`
const StatusText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #027a48;
`
const TagContainer = styled.div``
const TableContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-bottom: 32px;
`
const TableHeaderContainer = styled.div`
  padding: 12px 16px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 24px;
`
const TableHeaderButtons = styled.div`
  width: 182px;
  height: 40px;
  border: 1px solid #d0d5dd;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;
`
const TableHeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 87px;
  height: 100%;
  background: #f9fafb;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
  border-right: 1px solid #d0d5dd;
`
const TableHeaderButtonRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 87px;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
`
const InputGroupIcon = styled.img`
  width: 20px;
  height: 20px;
`
const FormControl = styled(Form.Control)`
  border-width: 0;
`
const InputGroupContainer = styled.div`
  width: 50%;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0 16px;
  align-items: center;
`
const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 24px;
  width: 236px;
  box-sizing: border-box;
`
const TableStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 24px;
  width: 128px;
  box-sizing: border-box;
`
const TableActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  width: 30vw;
  height: 40px;
  box-sizing: border-box;
`
const DocumentIconHolder = styled.div`
  width: 40px;
  height: 40px;
  background: rgba(243, 245, 247, 0.8);
  border-radius: 20px;
`
const DocumentTextHolder = styled.div`
  margin-left: 12px;
`
const DocumentText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  margin: 0;
`
const DocumentTextSub = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`
const TableActionButton = styled.div`
  width: 100px;
`
const TableActionText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  margin-left: 12px;
  width: 92px;
`

const TableFooterContainer = styled.div`
  border-radius: 0px 10px 10px 0px;
`
const TableFooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`
const TableFooterButtons = styled.div``
const TableFooterButton = styled.div`
  width: 120px;
  margin-left: 10px;
`

const ModalTitle = styled(Modal.Title)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`
const ModalSubTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

const ModalLeftIconHolder = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  padding: 15px 15px;
  margin-right: 16px;
`
const ModalLeftIcon = styled.img`
  height: 18px;
  width: 20px;
`
const RowText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`

const ModalUploadIcon = styled.img`
  height: 64px;
  width: 64px;
  margin-right: 20px;
`

const DropZoneConatainer = styled.section`
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 74px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`

const DropZoneTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 20px;
  color: #6941c6;
  text-align: center;
`
const DropZoneText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 20px;
  color: #475467;
  text-align: center;
  margin: 4px;
`
const InfoBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  width: 48px;
  height: 48px;
`

const InfoTitle = styled.p`
  color: #101828;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.48px;
`
const InfoDescription = styled.p`
  color: #475467;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 361px;
`

const InfoButtonHolder = styled.div`
  margin-top: 30px;
  width: 245px;
`

export default UserHome
