import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { userHasValidToken } from "../../api/auth"
// import HeaderNav from "../HeaderNav"
// import SidebarNav from "../SidebarNav"

const ProtectedRoute = ({ Page }) => {
  const hasValidToken = userHasValidToken()
  const [isExpanded, setIsExpanded] = useState(false)
  let navigate = useNavigate()
  let pathName = useLocation().pathname
  useEffect(() => {
    if (!hasValidToken) {
      navigate("/signin")
    }
  }, [])
  return hasValidToken ? <Page /> : <></>
}

export default ProtectedRoute
