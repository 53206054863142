import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/Col"
import houseIcon from "../../assets/house.png"
import addImageIcon from "../../assets/add-image.png"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import search from "../../assets/search.svg"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import CustomButton from "../../components/button/CustomButton"

import InputGroup from "react-bootstrap/InputGroup"

import moment from "moment"
import "primereact/resources/themes/lara-light-indigo/theme.css"
import { main_admin_api } from "../../api/axios_admin_helper"
import Notification from "../../components/Notification"
import AuthAdminHeader from "../../components/header/authAdminHeader"
import Dropzone from "react-dropzone"
import ResponseModal from "../../components/modal/ResponseModal"

function AdminUsers() {
  let navigate = useNavigate()
  const [isloading, setIsloading] = useState(false)
  const [pageData, setPageData] = useState({})

  const [applicationData, setApplicationData] = useState([])
  const [isUserModal, setIsUserModal] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeletedModal, setIsDeletedModal] = useState(false)
  const [isDeletingModal, setIsDeletingModal] = useState(false)
  const [isCreatedModal, setIsCreatedModal] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [usersId, setUsersId] = useState("")
  const [lastName, setLastName] = useState("")
  const [usersPhoto, setUserPhoto] = useState("")
  const [usersEmail, setUsersEmail] = useState("")
  const [usersRole, setUsersRole] = useState("")

  const _getApplications = url => {
    setIsloading(true)

    //Server should do this
    main_admin_api
      .get(url?.replace("http", "https") ?? `/admin/api/v1/users/?limit=10`)
      .then(({ data }) => {
        setIsloading(false)
        setApplicationData(data?.results)
        setPageData(data)
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const clearState = () => {
    setFirstName("")
    setLastName("")
    setUserPhoto("")
    setUsersEmail("")
    setUsersRole("")
    setUsersId("")
  }

  const _addUsers = () => {
    setIsloading(true)
    const payload = {
      username: usersEmail,
      email: usersEmail,
      first_name: firstName,
      last_name: lastName,
      is_staff: true,
      role: usersRole
    }
    main_admin_api
      .post(`/admin/api/v1/users/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        _getApplications()
        setIsUserModal(false)
        setIsUpdating(false)
        setIsCreatedModal(true)
        clearState()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }
  const _patchUsers = id => {
    setIsloading(true)
    const payload = {
      email: usersEmail,
      first_name: firstName,
      last_name: lastName,
      role: usersRole
    }
    main_admin_api
      .patch(`/admin/api/v1/users/${id}/`, payload)
      .then(({ data }) => {
        setIsloading(false)
        _getApplications()
        setIsUserModal(false)
        setIsUpdating(false)
        setIsCreatedModal(true)
        clearState()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  const _deleteUsers = id => {
    setIsloading(true)
    main_admin_api
      .delete(`/admin/api/v1/users/${id}/`)
      .then(({ data }) => {
        setIsloading(false)
        _getApplications()
        setIsUserModal(false)
        setIsUpdating(false)
        setIsDeletedModal(true)
        setIsDeletingModal(false)
        clearState()
      })
      .catch(error => {
        Notification.Error(
          error?.response?.data[Object.keys(error?.response?.data)[0]][0]
        )
        setIsloading(false)
      })
  }

  useEffect(() => {
    _getApplications()
  }, [])

  const titleTemplate = data => {
    return (
      <TableTitleContainer>
        <DocumentIconHolder src={`${data?.profile?.photo}`} />
        <DocumentTextHolder>
          <DocumentText>{`${data?.first_name} ${data?.last_name}`}</DocumentText>
          <DocumentTextSub>{`${data?.email}`}</DocumentTextSub>
        </DocumentTextHolder>
      </TableTitleContainer>
    )
  }
  const classTemplate = data => {
    return (
      <TableClassContainer>
        <RoleText className={data?.role == "Administrator" ? "bold" : "light"}>
          {data?.role}
        </RoleText>
      </TableClassContainer>
    )
  }
  const checkInTemplate = data => {
    return (
      <TableStatusContainer>
        {`${moment().format("MMM dd, YYYY")} at ${moment().format("h:mm a")}`}
      </TableStatusContainer>
    )
  }
  const actionTemplate = data => {
    return (
      <TableActionContainer className="d-flex justify-content-end">
        <TableActionButton>
          <CustomButton
            style={{ width: "90px", padding: "10px" }}
            onClick={() => {
              setFirstName(data.first_name)
              setLastName(data.last_name)
              setUserPhoto("")
              setUsersEmail(data.email)
              setUsersRole(data.role)
              setUsersId(data.id)

              setIsUpdating(true)
              setIsUserModal(true)
            }}
            title={"Edit Info"}
          />
          <CustomButton
            style={{
              width: "100px",
              padding: "10px",
              backgroundColor: "transparent",
              border: "none",
              color: "red"
            }}
            onClick={() => {
              setFirstName(data.first_name)
              setLastName(data.last_name)
              setUserPhoto("")
              setUsersEmail(data.email)
              setUsersRole(data.role)
              setUsersId(data.id)

              setIsUpdating(true)
              setIsDeletingModal(true)
            }}
            title={"Delete User"}
          />
        </TableActionButton>
      </TableActionContainer>
    )
  }

  const tableHeader = () => {
    return (
      <TableHeaderContainer className="d-flex justify-content-between align-items-center">
        <InputGroupContainer>
          <InputGroup className="d-flex align-items-center">
            <InputGroupIcon src={search} id="basic-addon1" />
            <FormControlSearch
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </InputGroupContainer>
      </TableHeaderContainer>
    )
  }
  const tableFooter = () => {
    return (
      <TableFooterContainer className="d-flex justify-content-between align-items-center">
        <TableFooterText>
          Page {applicationData?.length} of {applicationData?.length}
        </TableFooterText>
        <TableFooterButtons className="d-flex">
          <TableFooterButton>
            <CustomButton
              light={!Boolean(pageData?.previous)}
              onClick={() => {
                if (Boolean(pageData?.previous)) {
                  _getApplications(pageData?.previous)
                }
              }}
              title={"Previous"}
            />
          </TableFooterButton>
          <TableFooterButton>
            <CustomButton
              light={!Boolean(pageData?.next)}
              onClick={() => {
                if (Boolean(pageData?.next)) {
                  _getApplications(pageData?.next)
                }
              }}
              title={"Next"}
            />
          </TableFooterButton>
        </TableFooterButtons>
      </TableFooterContainer>
    )
  }
  return (
    <ContentContainer>
      <AuthAdminHeader />
      <MainContent>
        <ControledContainer>
          <Row>
            <Title>Users</Title>
            <CustomButton
              style={{
                display: "flex",
                padding: "10px 16px",
                justifyContent: "center",
                alignItems: "center",
                width: "165px",
                height: "40px"
              }}
              onClick={() => setIsUserModal(true)}
              title={"Create a new user"}
            />
          </Row>
          <TableContainer>
            <DataTable
              style={{ border: "none", maxWidth: "100%", overflow: "scroll" }}
              tableStyle={{
                border: "none",
                background: "green",
                maxWidth: "100%",
                overflow: "scroll",
                border: "1px solid #EAECF0",
                borderRadius: 120,
                width: "100%"
              }}
              footer={tableFooter}
              value={applicationData}
              header={tableHeader}
              emptyMessage="No pending request found."
              responsiveLayout="scroll"
              editMode="row"
              sortOrder={1}
            >
              <Column header="User's Name" body={titleTemplate} />
              <Column header="Role" body={classTemplate} />
              <Column header="Last Check-In" body={checkInTemplate} />
              <Column header="" body={actionTemplate} />
            </DataTable>
          </TableContainer>
        </ControledContainer>

        <Modal
          show={isUserModal}
          onHide={() => {
            setIsUserModal(false)
            setIsUpdating(false)
            clearState()
          }}
        >
          <Modal.Header closeButton>
            <ModalLeftIconHolder>
              <ModalLeftIcon src={houseIcon} />
            </ModalLeftIconHolder>
            <div className="d-flex flex-column justify-content-center">
              <ModalTitle>Create a new user</ModalTitle>
            </div>
          </Modal.Header>
          <Modal.Body>
            <>
              <Row className="mt-2 pt-3">
                <Col className="col-5">
                  <RowText>User's first name</RowText>
                </Col>
                <Col className="col-7">
                  <FormControl
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    type="text"
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row className="mt-2 pt-3">
                <Col className="col-5">
                  <RowText>User's last name</RowText>
                </Col>
                <Col className="col-7">
                  <FormControl
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    type="text"
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row className="mt-2 pt-3">
                <Col className="col-5">
                  <RowText>User's email address</RowText>
                </Col>
                <Col className="col-7">
                  <FormControl
                    value={usersEmail}
                    onChange={e => setUsersEmail(e.target.value)}
                    type="email"
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row className="mt-2 pt-3">
                <Col className="col-5">
                  <RowText>User's role</RowText>
                </Col>
                <Col className="col-7">
                  <Form.Select
                    aria-label="Default select example"
                    value={usersRole}
                    onChange={e => setUsersRole(e.target.value)}
                  >
                    <option>Choose a selection</option>
                    <option value="Administrator">Administrator</option>
                    <option value="Moderator">Moderator</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="mt-2 pt-3 border-top">
                <Col className="col-5">
                  <RowText>Upload user's photo</RowText>
                </Col>
                <Col className="col-7">
                  <Row>
                    <Col className="col-3">
                      <ModalUploadIcon src={addImageIcon} />
                    </Col>
                    <Col className="col-9">
                      <Dropzone
                        onDrop={acceptedFiles => setUserPhoto(acceptedFiles[0])}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <DropZoneConatainer>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <DropZoneText>
                                <DropZoneTitle>Click to upload </DropZoneTitle>
                                or drag and drop
                              </DropZoneText>
                              <DropZoneText>
                                SVG, PNG, JPG or GIF (max. 800x400px)
                              </DropZoneText>
                            </div>
                          </DropZoneConatainer>
                        )}
                      </Dropzone>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              title={isUpdating ? "Update user" : "Create user"}
              onClick={() => {
                isUpdating ? _patchUsers(usersId) : _addUsers(usersId)
              }}
            />
          </Modal.Footer>
        </Modal>

        <ResponseModal
          isShow={isCreatedModal}
          callback={() => setIsCreatedModal(!isCreatedModal)}
          // customIcon={alertIcon}
          title="Successfully created"
          description={`Lorem ipsum dolor sit amet consectetur. Fames volutpat at arcu molestie egestas tortor pharetra sit.`}
          buttonText="Go Back"
        />
        <ResponseModal
          isShow={isDeletedModal}
          callback={() => setIsDeletedModal(!isDeletedModal)}
          // customIcon={alertIcon}
          title="Deleted"
          description={`Lorem ipsum dolor sit amet consectetur. Fames volutpat at arcu molestie egestas tortor pharetra sit.`}
          buttonText="Go Back"
        />
        <Modal show={isDeletingModal} onHide={() => setIsDeletingModal(false)}>
          <Modal.Header closeButton>
            <ModalLeftIconHolder>
              <ModalLeftIcon src={houseIcon} />
            </ModalLeftIconHolder>
            <div className="d-flex flex-column justify-content-center">
              <ModalTitle>{`Delete ${firstName} ${lastName}`}</ModalTitle>
              <ModalSubTitle>
                Are you sure you want to delete this user from the
                database?Please note this action is not reversable
              </ModalSubTitle>
            </div>
          </Modal.Header>
          <Modal.Footer>
            <CustomButton
              style={{ backgroundColor: "#D92D20", borderColor: "#D92D20" }}
              title={"Delete user"}
              onClick={() => {
                _deleteUsers(usersId)
              }}
            />
          </Modal.Footer>
        </Modal>
      </MainContent>
    </ContentContainer>
  )
}
const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #f9fafb;
`
const ControledContainer = styled(Container)`
  @media (min-width: 992px) {
    margin-left: 240px;
  }
`
const MainContent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
  @media (min-width: 992px) {
    margin-top: 32px;
  }
  margin-top: 15vh;
`
const ClassCardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  height: 176px;
  padding: 24px;

  @media (max-width: 575.98px) {
    height: 270px;
  }
`
const ClassTag = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-bottom: 8px;
`
const ClassTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
`
const ClassTitleContainer = styled.div`
  margin-bottom: 16px;

  @media (max-width: 575.98px) {
    flex-direction: column;
  }
`
const ButtonContainer = styled.div`
  min-width: 130px;

  @media (max-width: 575.98px) {
    width: 100%;
  }
`
const CalendarContainer = styled.div`
  width: 250px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 11.67px 18.67px;
`
const CalendarIcon = styled.img`
  width: 15px;
  height: 100%;
  margin-right: 10px;
`
const CalendarDate = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`

const StatusTag = styled.div`
  background: #ecfdf3;
  mix-blend-mode: multiply;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px 2px 8px;
  gap: 4px;
  height: 24px;
  margin-left: 16px;
`
const StatusImg = styled.img`
  width: 12px;
  height: 12px;
`
const DocStatusTag = styled.div`
  background: #ecfdf3;
  mix-blend-mode: multiply;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px 2px 8px;
  gap: 4px;
  height: 24px;
`
const DocStatusText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #027a48;
`
const StatusText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #027a48;
`
const TagContainer = styled.div``
const TableContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-bottom: 32px;
`
const TableHeaderContainer = styled.div`
  padding: 12px 16px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 24px;
`
const TableHeaderButtons = styled.div`
  width: 182px;
  height: 40px;
  border: 1px solid #d0d5dd;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;
`
const TableHeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 87px;
  height: 100%;
  background: #f9fafb;
  border-radius: 8px 0px 0px 8px;
  cursor: pointer;
  border-right: 1px solid #d0d5dd;
`
const TableHeaderButtonRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 87px;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
`
const InputGroupIcon = styled.img`
  width: 20px;
  height: 20px;
`
const FormControl = styled(Form.Control)`
  border-width: 1;
`
const FormControlSearch = styled(Form.Control)`
  border-width: 0;
`
const InputGroupContainer = styled.div`
  width: 50%;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0 16px;
  align-items: center;
`
const TableTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 24px;
  width: 400px;
  box-sizing: border-box;
`
const TableClassContainer = styled.div`
  align-items: center;
  width: 190px;
  box-sizing: border-box;
`
const TableStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  box-sizing: border-box;
`
const TableActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  width: 300px;
  height: 40px;
  box-sizing: border-box;
`
const DocumentIconHolder = styled.img`
  width: 40px;
  height: 40px;
  background: rgba(243, 245, 247, 0.8);
  border-radius: 20px;
`
const DocumentTextHolder = styled.div`
  margin-left: 12px;
`
const RoleText = styled.p`
  margin: 0px;
  &.bold {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #475467;
  }
  &.light {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
  }
`
const DocumentText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
  margin: 0;
`
const DocumentTextSub = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`
const TableActionButton = styled.div`
  width: 300px;
`
const TableActionText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  margin-left: 12px;
  width: 92px;
`

const TableFooterContainer = styled.div`
  border-radius: 0px 10px 10px 0px;
`
const TableFooterText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`
const TableFooterButtons = styled.div``
const TableFooterButton = styled.div`
  width: 120px;
  margin-left: 10px;
`

const ModalTitle = styled(Modal.Title)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
`
const ModalSubTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin: 0;
`

const ModalLeftIconHolder = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  padding: 15px 15px;
  margin-right: 16px;
`
const ModalLeftIcon = styled.img`
  height: 18px;
  width: 20px;
`
const RowText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  or: #344054;
`

const ModalUploadIcon = styled.img`
  height: 64px;
  width: 64px;
  margin-right: 20px;
`

const DropZoneConatainer = styled.section`
  display: flex;
  width: 100%;
  max-width: 512px;
  height: 74px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`

const DropZoneTitle = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 20px;
  color: #6941c6;
  text-align: center;
`
const DropZoneText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 20px;
  color: #475467;
  text-align: center;
  margin: 4px;
`

const TableProgressContainer = styled.div`
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const ProgressContainer = styled.div`
  background: #eaecf0;
  width: 50px;
  height: 8px;
  position: relative;
  margin-right: 12px;
  border-radius: 8px;
`
const Progress = styled.div`
  background: #219653;
  position: absolute;
  width: ${props => (props?.size ? props.size : "10%")};
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
`
const ProgressText = styled.div`
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const Row = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export default AdminUsers
