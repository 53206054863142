import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import mailIcon from "../../assets/mail.png"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import CustomButton from "../../components/button/CustomButton"

function SignupMail() {
  let navigate = useNavigate()
  const { state } = useLocation()
  const { email } = state
  return (
    <ContentContainer>
      <CenterContentContainer>
        <div className="d-flex justify-content-center">
          <img src={mailIcon} width="60px" height="60px" />
        </div>
        <Title className="d-flex justify-content-center">
          Check your email
        </Title>
        <Text className="text-center">
          We sent an OTP link to {email}
        </Text>
        <Form>
          <ButtonContainer>
            <CustomButton
              title={"Enter code manually"}
              onClick={() => navigate("/signup/code", { state: { email } })}
            />
          </ButtonContainer>
        </Form>

        <FooterTextContainer className="d-flex justify-content-center">
          <ForgotText onClick={() => navigate("/signin")}>
            Back to log in
          </ForgotText>
        </FooterTextContainer>
      </CenterContentContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled(Container)`
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 100px;
  justify-content: center;
`
const CenterContentContainer = styled.div`
  width: 360px;
`
const Title = styled.h1`
  margin-top: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: val(--text-title-color);
`
const Text = styled.p`
  margin-top: 12px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: val(--text-color);
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0;
`
const FormLabel = styled(Form.Label)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`
const FormControl = styled(Form.Control)`
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

const ForgotText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183464;
  margin-bottom: 24px;
  cursor: pointer;
`

const ForgotTextAlt = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  margin-right: 4px;
  margin-bottom: 24px;
`

const FooterTextContainer = styled.div`
  margin-top: 32px;
`

export default SignupMail
